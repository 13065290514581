import axios from "axios";

let APP_AUDIO_TRANSCRIPTION_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_AUDIO_TRANSCRIPTION_API_URL =
    process.env.REACT_APP_AUDIO_TRANSCRIPTION_URL || "/";
} else {
  APP_AUDIO_TRANSCRIPTION_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_AUDIO_TRANSCRIPTION_URL;
}

export const appAudioTranscriptionApi = axios.create({
  baseURL: APP_AUDIO_TRANSCRIPTION_API_URL,
});
