import { appRouterPaths } from "../../../services/navigation/urls";
import type { AppRouteProps } from "../types";
import {AssistantPage} from "../../../pages/AssistantPage";
import {Assistant} from "../../../features/Assistants/components";

export const assistantsPageRoute: AppRouteProps = {
  path: appRouterPaths.assistants,
  element: <AssistantPage />,
  name: "Ассистенты",
};

export const assistantPageRoute: AppRouteProps = {
  path: appRouterPaths.assistant,
  element: <Assistant />,
  name: "Ассистент",
};
