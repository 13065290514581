import type { FC} from "react";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";

interface Props {
  userMessage: string;
  isLoading?: boolean;
}
export const UserMessage: FC<Props> = ({ userMessage, isLoading }) => {
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [userMessage]);

  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div ref={messageRef} className={styles["user-message"]}>
      {userMessage}
    </div>
  );
};
