import { APP_MULTI_USER_API } from "../../../../services/api/constants";
import { recreateAppBotApi } from "../../../../services/api/instances/botApiInstance";
import { recreateAppModelsApi } from "../../../../services/api/instances/modelsApiInstance";
import { recreateAppThreadApi } from "../../../../services/api/instances/threadApiInstance";
import { getUserInfo, login } from "../../../../services/api/methods";
import type {
  AuthUserType,
  UserInfo,
} from "../../../../services/api/methodsTypes";
import { multiUserApi } from "../../../../shared/utils/multiUserFunctions";
import { navigateToRoute } from "../../../../shared/utils/navigate-to-route";
import type { ValidationSchema } from "../types";

export const loginNewUser = (username: string, password: string) => {
  return login(username, password).then((res) => {
    const multiUser: AuthUserType = {
      user_name: username,
      access_token: res.access_token,
      refresh_token: res.refresh_token,
      isActive: true,
      replacement_token: "",
    };
    multiUserApi.addUser(multiUser);
    window.location.reload();
  });
};

export const loginUser = (
  username: string,
  password: string,
  setUser: (userInfo: UserInfo | null) => void,
  navigate: any
) => {
  return login(username, password).then((res) => {
    const multiUser: AuthUserType = {
      user_name: username,
      access_token: res.access_token,
      refresh_token: res.refresh_token,
      isActive: true,
      replacement_token: "",
    };
    localStorage.setItem(APP_MULTI_USER_API, JSON.stringify([multiUser]));
    getUserInfo().then((response) => {
      if (response.replacement_token) {
        localStorage.setItem(
          APP_MULTI_USER_API,
          multiUserApi.setUserKey(
            multiUserApi.parseToArray(),
            "replacement_token",
            response.replacement_token
          )
        );
      }
      recreateAppThreadApi(
        setUser,
        !!response.replacement_token,
        response.thread_url
      );
      recreateAppModelsApi(setUser, response.thread_model_url);
      recreateAppBotApi(
        setUser,
        !!response.replacement_token,
        response.tg_bot_service_url
      );

      setUser(response);
      navigateToRoute(response["user-groups"], navigate);
    });
  });
};

export const validation = (
  validationObject: ValidationSchema,
  username: string,
  password: string,
  setError: (value: ValidationSchema) => void
) => {
  let tempWrongData = validationObject;

  if (!username) {
    tempWrongData = { ...tempWrongData, username: true };
    setError(tempWrongData);
    return;
  }
  if (!password) {
    tempWrongData = { ...tempWrongData, password: true };
    setError(tempWrongData);
    return;
  }

  tempWrongData = { ...tempWrongData, credentials: true };
  setError(tempWrongData);
};
