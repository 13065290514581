import type { InputProps } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { AppInput } from "../../../../shared/components/AppInput";
import { UserIcon } from "../../../../shared/icons/UserIcon";
import styles from "../styles.module.css";
import type { ChangeEvent, FC } from "react";

interface Props extends InputProps {
  username: string;
  onChange: (
    value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

export const LoginInput: FC<Props> = ({ username, onChange, ...props }) => {
  return (
    <AppInput
      value={username}
      onChange={onChange}
      name={"login"}
      type="text"
      startAdornment={
        <InputAdornment position="start">
          <UserIcon />
        </InputAdornment>
      }
      placeholder="Логин"
      className={styles.input}
      inputClassName={styles["inner-input"]}
      {...props}
    />
  );
};
