import type { SwitchProps } from "@mui/material";
import { Switch } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends SwitchProps {
  className?: string;
}

export const AppSwitch: React.FC<Props> = ({ className, ...props }) => {
  return (
    <Switch
      className={classNames(styles.switch, className)}
      classes={{
        switchBase: styles.switchBase,
        track: styles.track,
        thumb: styles.thumb,
        input: styles.input,
        checked: styles.checked,
        disabled: styles.disabled,
      }}
      disableRipple
      {...props}
    />
  );
};
