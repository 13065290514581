import type { ChangeEvent, FC } from "react";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import type { InputBaseProps } from "@mui/material";
import styles from "./styles.module.css";

interface Props extends InputBaseProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
}

export const StylistTextArea: FC<Props> = ({
  value,
  onChange,
  isDisabled,
  ...props
}) => {
  return (
    <AppInputBase
      value={value}
      onChange={onChange}
      className={styles["text-area"]}
      disabled={isDisabled}
      multiline
      classes={{
        input: styles["text-area-inner"],
      }}
      {...props}
    />
  );
};
