import type { ButtonProps } from "@mui/material";
import { AppButton } from "../../../shared/components/AppButton";
import styles from "./styles.module.css";

let APP_SUPPORT_URL: string | undefined;
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;
} else {
  // @ts-ignore
  APP_SUPPORT_URL = window.__RUNTIME_CONFIG__?.REACT_APP_SUPPORT_URL;
}

export const SupportButton: React.FC<ButtonProps> = ({ ...props }) => {
  if (!APP_SUPPORT_URL) {
    return null;
  }

  return (
    <AppButton
      href={APP_SUPPORT_URL}
      buttonType={"contained"}
      className={styles.support}
      {...props}
    >
      Support
    </AppButton>
  );
};
