import type { ReactNode } from "react";
import { CopyButton } from "../../../../../shared/components/Buttons/CopyButton";
import styles from "./styles.module.css";

interface Props {
  content: string | ReactNode;
}

export const AudioTranscriptionSuccess: React.FC<Props> = ({ content }) => {
  return (
    <div className={styles.success}>
      <CopyButton
        onClick={() => navigator.clipboard.writeText(content as string)}
        className={styles.copy}
      />
      {content}
    </div>
  );
};
