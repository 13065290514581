import type { FC } from "react";
import { AppDialog } from "../../../shared/components/AppDialog";
import styles from "./styles.module.css";
import classNames from "classnames";
import type {ModalBasicProps} from "../../../features/Modals/ModalBasic/types";
import {ModalBasicHeader} from "./ModalBasicHeader";
import {ModalBasicFooter} from "../../../features/Modals/ModalBasic/ModalBasicFooter";

interface Props extends ModalBasicProps {
  onOk?: () => void;
  onCancel?: () => void;
  disabledOkBtn?: boolean;
  disabledCancelBtn?: boolean;
  hasHeaderPaddingBottom?: boolean;
}

export const ModalBasic: FC<Props> = ({
  title,
  children,
  hasCloseButton,
  hasActions,
  closeModal,
  hasFooter,
  onOk,
  onCancel,
  disabledOkBtn,
  disabledCancelBtn,
  hasHeaderPaddingBottom,
  ...props
}) => {
  return (
    <AppDialog
      classes={{
        paper: styles.modal,
      }}
      {...props}
    >
      <div className={styles["modal-container"]}>
        <ModalBasicHeader
          title={title as string}
          hasCloseButton={hasCloseButton}
          onClick={closeModal}
          className={classNames({
            [styles["header-padding"]]: hasHeaderPaddingBottom,
          })}
        />
        {children}
        {hasFooter && (
          <ModalBasicFooter
            onOk={onOk}
            onCancel={onCancel}
            disabledCancelBtn={disabledCancelBtn}
            disabledOkBtn={disabledOkBtn}
          />
        )}
      </div>
    </AppDialog>
  );
};
