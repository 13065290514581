import squares from "../../shared/assets/img/squares.png";
import logo from "../../shared/assets/img/logo_light.png";
import styles from "./styles.module.css";
import { AuthForm } from "./AuthForm";
import { default as version } from "../../../package.json";

export const LoginPage = () => {
  return (
    <div className={styles["page-layout"]}>
      <div className={styles["logo-container"]}>
        <img className={styles.logo} src={logo} alt="Логотип Гуреев" />
        <p className={styles.version}>{version.version}-dev</p>
      </div>
      <AuthForm btnText="Вход" />
      <img className={styles["background-squares"]} src={squares} alt="" />
    </div>
  );
};
