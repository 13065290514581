import type { LinearProgressProps } from "@mui/material";
import { LinearProgress } from "@mui/material";
import classNames from "classnames";
import styles from "../styles.module.css";

type Props = LinearProgressProps & {
  className?: string;
};

export const AppLinearLoading: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <LinearProgress className={classNames(styles.loading, className)} />
    </div>
  );
};
