import type { ChangeEvent } from "react";
import { useCallback } from "react";
import { debounce } from "throttle-debounce";
import { AssistantFunctionBlock } from "./AssistantFunctionBlock";
import classNames from "classnames";
import styles from "./styles.module.css";
import * as React from "react";
import type {
  AssistantFunction,
  AssistantInfo,
  CompModel,
  StoreData,
} from "../../../../services/api/methodsTypes";
import { useAppDispatch } from "../../../../services/redux/hooks/use-dispatch";
import {
  setFunctionsAction,
  updateSelectedAssistantAction,
} from "../../redux/slice";
import { updateAssistantToolsAndResources } from "../../../../shared/utils/assistantPageFunctions";
import { getAssistantFunctionSchema } from "../../../../services/api/methods";
import { AppUnderlinedTitle } from "../../../../shared/components/AppUnderlinedTitle";
import { AppLabel } from "../../../../shared/components/AppLabel";
import { AppInput } from "../../../../shared/components/AppInput";
import { AppInputBase } from "../../../../shared/components/AppInputBase";
import { AppSelect } from "../../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../../shared/components/AppSelect/AppSelectOption";
import { AppToggleButtonGroup } from "../../../../shared/components/AppToggleButtonGroup";
import { AppToggleButton } from "../../../../shared/components/AppToggleButtonGroup/AppToggleButton";
import { AppLoading } from "../../../../shared/components/AppLoading";
import { AppButton } from "../../../../shared/components/AppButton";
import { PlusIcon } from "../../../../shared/icons/PlusIcon";
import { AppSlider } from "../../../../shared/components/AppSlider";

interface Props {
  models: CompModel[];
  selectedAssistant: AssistantInfo;
  openCreateFunctionModal: () => void;
  saveAssistant: (assistant: AssistantInfo) => void;
  stores: StoreData[];
  isSendDisabled: boolean;
  functions: AssistantFunction[];
}

export const AssistantSettings: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const saveAssistant = useCallback(
    debounce(1000, (assistant: AssistantInfo) => {
      props.saveAssistant(assistant);
    }),
    []
  );

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedAssistant = {
      ...props.selectedAssistant!,
      name: event.target.value,
    };
    dispatch(updateSelectedAssistantAction(updatedAssistant));
    saveAssistant(updatedAssistant);
  };

  const onInstructionsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedAssistant = {
      ...props.selectedAssistant!,
      instructions: event.target.value,
    };
    dispatch(updateSelectedAssistantAction(updatedAssistant));
    saveAssistant(updatedAssistant);
  };

  const handleTemperatureChange = (_: any, value: number | number[]) => {
    const nextValue = (value as number) / 100;
    const updatedAssistant = {
      ...props.selectedAssistant!,
      temperature: nextValue,
    };
    dispatch(updateSelectedAssistantAction(updatedAssistant));
    saveAssistant(updatedAssistant);
  };

  const handleModelChange = (event: any) => {
    const updatedAssistant = {
      ...props.selectedAssistant!,
      model: event.target.value,
    };
    dispatch(updateSelectedAssistantAction(updatedAssistant));
    props.saveAssistant(updatedAssistant);
  };

  const handleResponseFormatToggle = (
    _: React.MouseEvent<HTMLElement>,
    value: "auto" | "json_object"
  ) => {
    if (value === props.selectedAssistant.response_format) {
      return;
    }

    const updatedAssistant = {
      ...props.selectedAssistant!,
      response_format: value === "auto" ? "auto" : "json_object",
    };

    const nextAssistant = updateAssistantToolsAndResources(updatedAssistant);

    dispatch(updateSelectedAssistantAction(nextAssistant));
    props.saveAssistant(nextAssistant);
  };

  const handleStoreChange = (value: string) => {
    console.log("value", value);
    if (value === "emptyId") {
      clearDataStore();
      return;
    }
    const updatedAssistant: AssistantInfo = {
      ...props.selectedAssistant!,
      tool_resources: {
        file_search: {
          vector_store_ids: [value],
        },
      },
    };

    if (
      !props.selectedAssistant.tools.find((tool) => tool.type === "file_search")
    ) {
      updatedAssistant.tools = [
        ...props.selectedAssistant.tools,
        { type: "file_search" },
      ];
    }

    dispatch(updateSelectedAssistantAction(updatedAssistant));
    props.saveAssistant(updatedAssistant);
  };

  const clearDataStore = () => {
    if (
      !props.selectedAssistant.tool_resources?.file_search
        ?.vector_store_ids?.[0]
    ) {
      return;
    }
    const updatedAssistant: AssistantInfo = {
      ...props.selectedAssistant!,
      tool_resources: {
        file_search: {
          vector_store_ids: [],
        },
      },
      tools: props.selectedAssistant.tools.filter(
        (tool) => tool.type !== "file_search"
      ),
    };

    dispatch(updateSelectedAssistantAction(updatedAssistant));
    props.saveAssistant(updatedAssistant);
  };

  const handleFunctionChange = (event: any, functionName: string) => {
    const nextFunctions = props.functions.map((item: AssistantFunction) =>
      item.function_name === functionName
        ? { ...item, selected: event.target.checked }
        : item
    );

    dispatch(setFunctionsAction(nextFunctions));

    if (event.target.checked) {
      getAssistantFunctionSchema(
        props.selectedAssistant.id!,
        functionName
      ).then((schema) => {
        const updatedAssistant: AssistantInfo = {
          ...props.selectedAssistant,
          tools: [
            ...props.selectedAssistant.tools,
            {
              type: "function",
              function: schema.schema,
            },
          ],
        };
        dispatch(updateSelectedAssistantAction(updatedAssistant));
        props.saveAssistant(updatedAssistant);
      });
    } else {
      const updatedAssistant: AssistantInfo = {
        ...props.selectedAssistant,
        tools: props.selectedAssistant.tools.filter(
          (tool: any) => tool.function?.name !== functionName
        ),
      };
      dispatch(updateSelectedAssistantAction(updatedAssistant));
      props.saveAssistant(updatedAssistant);
    }
  };

  const calculateValue = (value: number) => {
    return value / 100;
  };

  return (
    <div className={styles.layout}>
      <div className={styles["top-container"]}>
        <AppUnderlinedTitle className={styles.title} leftContent="Инструкция" />
        <AppLabel className={styles.text}>
          {`ID Ассистента: ${props.selectedAssistant.id}`}
        </AppLabel>
        <AppInput
          value={props.selectedAssistant.name}
          onChange={onNameChange}
          className={styles.input}
          placeholder="Название ассистента"
        />
        <AppInputBase
          value={props.selectedAssistant.instructions}
          onChange={onInstructionsChange}
          className={styles["text-area"]}
          classes={{
            input: styles["text-area-inner"],
          }}
          placeholder="Инструкция"
          rows={10}
        />

        <div className={styles["select-container"]}>
          <AppLabel className={styles.text}>Выбор модели</AppLabel>
          <div className={styles["models-container"]}>
            {props.models?.length ? (
              <>
                <AppSelect
                  value={props.selectedAssistant.model}
                  onChange={handleModelChange}
                  className={classNames(styles.select, styles["model-select"])}
                  itemText={
                    props.models?.find(
                      (model) =>
                        model.model_name === props.selectedAssistant.model
                    )?.friendly_name
                  }
                  disabled={props.isSendDisabled}
                >
                  {props.models?.map((model, index) => (
                    <AppSelectOption key={index} value={model.model_name}>
                      {model.friendly_name}
                    </AppSelectOption>
                  ))}
                </AppSelect>
                <AppToggleButtonGroup
                  value={props.selectedAssistant.response_format}
                  exclusive
                  onChange={handleResponseFormatToggle}
                  disabled={props.isSendDisabled}
                >
                  <AppToggleButton value={"auto"}>AUTO</AppToggleButton>
                  <AppToggleButton value={"json_object"}>JSON</AppToggleButton>
                </AppToggleButtonGroup>
              </>
            ) : (
              <AppLoading small />
            )}
          </div>
          <div className={styles["function-container"]}>
            <AppUnderlinedTitle className={styles.title} leftContent="Функции">
              <AppButton
                small
                buttonType="text"
                onClick={props.openCreateFunctionModal}
                icon={<PlusIcon />}
              >
                Добавить функцию
              </AppButton>
            </AppUnderlinedTitle>
            <div className={styles["function-list"]}>
              {props.functions.map((item) => (
                <AssistantFunctionBlock
                  className={styles["function-block"]}
                  key={item.function_name}
                  functionName={item.friendly_name}
                  isActive={!!item.selected}
                  handleSwitchChange={(e) =>
                    handleFunctionChange(e, item.function_name)
                  }
                />
              ))}
            </div>
          </div>
          {!!props.stores?.length &&
            props.selectedAssistant.response_format === "auto" && (
              <>
                <AppLabel className={styles.text}>
                  Подключить базу знаний
                </AppLabel>
                <div className={styles.storesSelectContainer}>
                  <AppSelect
                    value={
                      props.selectedAssistant?.tool_resources?.file_search
                        ?.vector_store_ids?.[0] || "emptyId"
                    }
                    onChange={(event) =>
                      handleStoreChange(event.target.value as string)
                    }
                    className={styles.select}
                    itemText={
                      props.stores?.find(
                        (store: any) =>
                          store.id ===
                          props.selectedAssistant?.tool_resources?.file_search
                            ?.vector_store_ids?.[0]
                      )?.name || "Без базы знаний"
                    }
                    disabled={
                      props.selectedAssistant.response_format !== "auto"
                    }
                  >
                    <AppSelectOption key="emptyId" value="emptyId">
                      Без базы знаний
                    </AppSelectOption>
                    {props.stores?.map((store: any) => (
                      <AppSelectOption key={store.id} value={store.id}>
                        {store.name}
                      </AppSelectOption>
                    ))}
                  </AppSelect>
                </div>
              </>
            )}
        </div>
        <div className={styles["slider-container"]}>
          <AppUnderlinedTitle
            className={styles["temperature-title"]}
            leftContent="Температура"
          />
          <p className={styles["temperature-label"]}>
            {props.selectedAssistant.temperature.toFixed(2)}
          </p>
          <AppSlider
            value={props.selectedAssistant.temperature * 100}
            min={1}
            max={100}
            scale={calculateValue}
            onChange={handleTemperatureChange}
          />
        </div>
      </div>
    </div>
  );
};
