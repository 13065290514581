import { AppDialog } from "../..";
import { AppButton } from "../../../AppButton";
import { AppLabel } from "../../../AppLabel";
import { AppInput } from "../../../AppInput";
import type {
  TokenError} from "../../../../utils/chatbotFunctions";
import {
  getIsTokenExisting,
  getIsTokenWrong
} from "../../../../utils/chatbotFunctions";
import { AppLoading } from "../../../AppLoading";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  dialogOpen: boolean;
  token: string;
  setToken: (value: string) => void;
  handleDialogClose: () => void;
  handleClick: () => void;
  tokenErrors: TokenError[];
  isTokenCheckLoading: boolean;
}

export const AppNewBotDialog: React.FC<Props> = ({
  className,
  dialogOpen,
  token,
  setToken,
  handleDialogClose,
  handleClick,
  tokenErrors,
  isTokenCheckLoading,
}) => {
  const getErrorMessage = () => {
    let errorMessage = "";
    if (getIsTokenWrong(tokenErrors)) {
      errorMessage =
        tokenErrors.find((error) => error.errorCode === 404)?.message || "";
    }
    if (getIsTokenExisting(tokenErrors)) {
      errorMessage =
        tokenErrors.find((error) => error.errorCode === 500)?.message || "";
    }
    return errorMessage;
  };

  return (
    <AppDialog
      classes={{ paper: classNames(styles.dialog, className) }}
      open={dialogOpen}
    >
      <h1 className={styles["dialog-title"]}>Новый бот</h1>
      <div className={classNames(styles["input-container"])}>
        <AppLabel className={styles.label}>Токен Telegram-бота</AppLabel>
        <AppInput
          value={token}
          onChange={(e) => setToken(e.target.value)}
          className={classNames(styles.input, {
            [styles.error]:
              getIsTokenWrong(tokenErrors) || getIsTokenExisting(tokenErrors),
          })}
        />
        <AppLabel
          className={classNames(styles["error-label"], {
            [styles.hidden]: !(
              getIsTokenWrong(tokenErrors) || getIsTokenExisting(tokenErrors)
            ),
          })}
        >
          {getErrorMessage()}
        </AppLabel>
        {isTokenCheckLoading && <AppLoading small />}
      </div>

      <div className={styles["dialog-button-container"]}>
        <AppButton small buttonType="outlined" onClick={handleDialogClose}>
          Отмена
        </AppButton>
        <AppButton small buttonType="contained" onClick={handleClick}>
          Добавить
        </AppButton>
      </div>
    </AppDialog>
  );
};
