import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import type { ChartEntry } from "./MyClassChart";
import { MyClassChart } from "./MyClassChart";
import type { PredictionObject } from "../../../services/api/methodsTypes";
import type { CardInputValues } from "../ClassLeftContainer";
import styles from "./styles.module.css";
import { AppInputBase } from "../../../shared/components/AppInputBase";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";

interface Props {
  data: PredictionObject | null;
  leftData: CardInputValues[];
  requestBody: (data: ClassInputValues) => string;
  isLoading: boolean;
  isFetched: boolean;
  onClassification: (data: ClassInputValues) => void;
}

export type ClassInputValues = {
  instruction: string;
  classification: string;
};

export const classRightInputValues: ClassInputValues = {
  instruction: "",
  classification: "",
};

export const ClassRightContainer: React.FC<Props> = ({
  data,
  leftData,
  requestBody,
  isLoading,
  isFetched,
  onClassification,
}) => {
  const [inputValues, setInputValues] = useState<ClassInputValues>(
    classRightInputValues
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (id: string, nextValue: string) => {
    setInputValues((prevValues) => {
      return {
        ...prevValues,
        [id]: nextValue,
      };
    });
  };

  const checkVariantUniqueness = (data: CardInputValues[]) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].variant === data[j].variant) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const validVariants = leftData.filter((card) => card.variant.trim() !== "");
    const areVariantsUnique = checkVariantUniqueness(leftData);

    if (
      inputValues.classification &&
      validVariants.length >= 2 &&
      areVariantsUnique
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputValues, leftData]);

  let chartData: ChartEntry[] = [];

  if (data) {
    chartData = data.predictions
      .filter((item) => item.label)
      .map((item) => {
        return {
          label: item.label,
          value: item.confidence,
        };
      });
  }

  const chartRenderLogic = (isFetched: boolean, isLoading: boolean) => {
    if (!isFetched && isLoading) {
      return (
        <div className={styles["loader-container"]}>
          <CircularProgress size={50} color="success" />
        </div>
      );
    }
    if (isFetched) return <MyClassChart data={chartData} />;
  };

  return (
    <div className={styles["right-container"]}>
      <AppUnderlinedTitle
        className={styles.title}
        leftContent="Общая инструкция"
      />
      <AppInputBase
        small
        minRows={4}
        rows={undefined}
        placeholder="Общая инструкция"
        className={styles["text-area"]}
        onChange={(e) => handleInputChange("instruction", e.target.value)}
      />
      <AppUnderlinedTitle
        className={styles.title}
        leftContent="Классификация"
      />
      <AppInputBase
        small
        minRows={4}
        rows={undefined}
        placeholder="Классификация"
        className={styles["text-area"]}
        onChange={(e) => handleInputChange("classification", e.target.value)}
      />
      <AppButton
        className={styles.button}
        buttonType="outlined"
        onClick={() => onClassification(inputValues)}
        disabled={isDisabled || isLoading}
      >
        Классифицировать
      </AppButton>
      <div className={styles["chart-container"]}>
        {chartRenderLogic(isFetched, isLoading)}
      </div>
      <div className={styles["wrapper"]}>
        <pre>{requestBody(inputValues)}</pre>
        <CopyButton
          onClick={() =>
            navigator.clipboard.writeText(requestBody(inputValues))
          }
          position="absolute"
        />
      </div>
    </div>
  );
};
