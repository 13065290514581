import type { AppIconProps } from "../IconTypes";

export const BigXIcon: React.FC<AppIconProps> = ({
  width = 32,
  height = 32,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M24 8L8 24M8 8L24 24"
        stroke="#17384C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
