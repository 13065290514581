import classNames from "classnames";
import type { FC } from "react";
import type { ButtonProps } from "@mui/material";
import styles from "../styles.module.css";
import {AppButton} from "../../../../shared/components/AppButton";
import {PlusIcon} from "../../../../shared/icons/PlusIcon";

interface Props extends ButtonProps {
  onAdd?: () => void;
  isDisabled?: boolean;
}

export const AddButton: FC<Props> = ({ onAdd, isDisabled, ...props }) => {
  return (
    <AppButton
      onClick={onAdd}
      buttonType={"text"}
      icon={<PlusIcon />}
      className={classNames(styles["add-button"], {
        [styles.disabled]: isDisabled,
      })}
      {...props}
    >
      Добавить
    </AppButton>
  );
};
