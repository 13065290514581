import type { FC } from "react";
import { useEffect, useState } from "react";
import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import styles from "./styles.module.css";
import classNames from "classnames";
import { StylistTextArea } from "../StylistTextArea";
import { AppLoading } from "../../../shared/components/AppLoading";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";
import { ClearIcon } from "../../../shared/icons/ClearIcon";

interface Props {
  results: string;
  date: string;
  onChange: (e: any) => void;
  onClean: () => void;
  className?: string;
  isResultsDisabled?: boolean;
  isLoading?: boolean;
}

export const StylistResult: FC<Props> = ({
  results,
  date,
  onChange,
  onClean,
  isResultsDisabled,
  isLoading,
  className,
}) => {
  const [minRows, setMinRows] = useState(4);

  useEffect(() => {
    const calculateMinRows = () => {
      const windowHeight = window.innerHeight;
      const rowHeight = 32;
      const rows = Math.floor(windowHeight / rowHeight + 4);
      setMinRows(rows);
    };

    calculateMinRows();

    window.addEventListener("resize", calculateMinRows);

    return () => {
      window.removeEventListener("resize", calculateMinRows);
    };
  }, []);

  return (
    <div className={classNames(styles.result, className)}>
      <AppUnderlinedTitle className={styles.title} leftContent={"Результат"}>
        <AppButton
          buttonType={"text"}
          onClick={onClean}
          className={styles["button-clean"]}
          icon={<ClearIcon />}
        >
          Очистить
        </AppButton>
      </AppUnderlinedTitle>
      <div className={styles["text-area-container"]}>
        <StylistTextArea
          value={results}
          onChange={onChange}
          placeholder={"Результат"}
          minRows={minRows}
          disabled={isResultsDisabled}
        />
        <CopyButton
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(results);
          }}
          position="absolute"
        />
      </div>
      <div className={styles.date}>{date}</div>
      {isLoading && <AppLoading className={styles.loading} big />}
    </div>
  );
};
