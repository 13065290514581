import { AppButton } from "../../../shared/components/AppButton";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { ClassReviewCard } from "./ClassReviewCard";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import classNames from "classnames";
import styles from "./styles.module.css";
import type { inputValue } from "../../Embeddings/Sidebar";

interface Props {
  leftData: CardInputValues[];
  setLeftData: (data: any) => void;
}

export type CardInputValues = {
  cardId: string;
  variant: string;
  descValue: string;
  values: inputValue[];
};

export type ChangedInput = "variant" | "descValue" | "value";

export const ClassLeftContainer: React.FC<Props> = ({
  leftData,
  setLeftData,
}) => {
  const handleDeleteCard = (cardId: string) => {
    setLeftData(leftData.filter((card) => card.cardId !== cardId));
  };

  const handleDeleteInput = (cardId: string, inputId: string) => {
    setLeftData(
      leftData.map((card) => {
        if (card.cardId === cardId) {
          return {
            ...card,
            values: card.values.filter((input) => input.id !== inputId),
          };
        }
        return card;
      })
    );
  };

  const handleAddCard = () => {
    const newCard = {
      cardId: String(Math.random()),
      variant: "",
      descValue: "",
      values: [
        {
          id: String(Math.random()),
          value: "",
        },
      ],
    };
    setLeftData([...leftData, newCard]);
  };

  const handleAddInput = (cardId: string) => {
    const newInput = {
      id: String(Math.random()),
      value: "",
    };
    setLeftData(
      leftData.map((card) => {
        if (card.cardId === cardId) {
          return {
            ...card,
            values: [...card.values, newInput],
          };
        }
        return card;
      })
    );
  };

  const handleInputChange = (
    changedInput: ChangedInput,
    cardId: string,
    inputId: string,
    nextValue: string
  ) => {
    setLeftData((prevValues: CardInputValues[]) => {
      switch (changedInput) {
        case "value":
          return prevValues.map((card) => {
            if (cardId === card.cardId) {
              return {
                cardId,
                variant: card.variant,
                descValue: card.descValue,
                values: card.values.map((input) => {
                  if (inputId === input.id) {
                    return {
                      id: input.id,
                      value: nextValue,
                    };
                  } else {
                    return input;
                  }
                }),
              };
            } else {
              return card;
            }
          });

        case "descValue":
          return prevValues.map((card) => {
            if (cardId === card.cardId) {
              return {
                cardId,
                variant: card.variant,
                descValue: nextValue,
                values: card.values,
              };
            } else {
              return card;
            }
          });

        case "variant":
          return prevValues.map((card) => {
            if (cardId === card.cardId) {
              return {
                cardId,
                variant: nextValue,
                descValue: card.descValue,
                values: card.values,
              };
            } else {
              return card;
            }
          });
      }
    });
  };

  return (
    <div className={styles["left-container"]}>
      <AppUnderlinedTitle leftContent="Варианты" className={styles["title"]}>
        <AppButton
          small
          icon={<PlusIcon />}
          buttonType="text"
          className={classNames(styles.button, styles["add-button"])}
          onClick={handleAddCard}
        >
          Добавить
        </AppButton>
      </AppUnderlinedTitle>

      <div className={styles["card-container"]}>
        {leftData.map((card) => (
          <ClassReviewCard
            key={card.cardId}
            card={card}
            className={styles.card}
            onAddInput={handleAddInput}
            onDeleteCard={handleDeleteCard}
            onDeleteInput={handleDeleteInput}
            onInputChange={handleInputChange}
          />
        ))}
      </div>
    </div>
  );
};
