import { AppSelect } from "../../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../../shared/components/AppSelect/AppSelectOption";
import type { Model } from "../../../../services/api/methodsTypes";
import type { SelectChangeEvent } from "@mui/material/Select";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  onChange?: (value: SelectChangeEvent<any>) => void;
  models: Model[];
  selectedModel?: string;
}

export const SidebarSelect: React.FC<Props> = ({
  className,
  onChange,
  models,
  selectedModel,
}) => {
  return (
    <AppSelect
      className={className}
      displayEmpty
      renderValue={() => {
        if (selectedModel === "")
          return <span className={styles.placeholder}>Выберите модель</span>;
        else return selectedModel;
      }}
      value={selectedModel}
      onChange={onChange}
    >
      {models.map(({ model_name, name }) => (
        <AppSelectOption key={model_name} value={name} autoFocus={false}>
          {name}
        </AppSelectOption>
      ))}
    </AppSelect>
  );
};
