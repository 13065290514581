import type { AppIconProps } from "../IconTypes";

export const CopyIcon: React.FC<AppIconProps> = ({
  width = 16,
  height = 16,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 5.33329V3.99996C10.6667 3.64634 10.5263 3.3072 10.2762 3.05715C10.0262 2.8071 9.68704 2.66663 9.33341 2.66663H4.00008C3.64646 2.66663 3.30732 2.8071 3.05727 3.05715C2.80722 3.3072 2.66675 3.64634 2.66675 3.99996V9.33329C2.66675 9.68691 2.80722 10.0261 3.05727 10.2761C3.30732 10.5261 3.64646 10.6666 4.00008 10.6666H5.33341M5.33341 6.66663C5.33341 6.313 5.47389 5.97387 5.72394 5.72382C5.97399 5.47377 6.31313 5.33329 6.66675 5.33329H12.0001C12.3537 5.33329 12.6928 5.47377 12.9429 5.72382C13.1929 5.97387 13.3334 6.313 13.3334 6.66663V12C13.3334 12.3536 13.1929 12.6927 12.9429 12.9428C12.6928 13.1928 12.3537 13.3333 12.0001 13.3333H6.66675C6.31313 13.3333 5.97399 13.1928 5.72394 12.9428C5.47389 12.6927 5.33341 12.3536 5.33341 12V6.66663Z"
        stroke="#7A909C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
