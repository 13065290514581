import type { FC } from "react";
import styles from "../styles.module.css";
import type { ButtonProps } from "@mui/material";
import {AppButton} from "../../../../shared/components/AppButton";
import {SparksIcon} from "../../../../shared/icons/SparksIcon";
import classNames from "classnames";

interface Props extends ButtonProps {
  openAskGPT: () => void;
}

export const AskGPTButton: FC<Props> = ({ openAskGPT, ...props }) => {
  return (
    <AppButton
      buttonType={"text"}
      onClick={openAskGPT}
      icon={<SparksIcon />}
      className={classNames(styles.button, styles["ask-gpt-button"])}
      {...props}
    >
      Спросить GPT
    </AppButton>
  );
};
