import { IconButton } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AddButton } from "./SidebarButtons/AddButton";
import { SidebarFooter } from "./SidebarFooter";
import { AskGPTButton } from "./SidebarButtons/AskGPTButton";
import { useState } from "react";
import { AppLoading } from "../../shared/components/AppLoading";
import { TrashIcon } from "../../shared/icons/TrashIcon";
import { AudioTranscription } from "../../features/Modals/AudioTranscription";
import { ChatModal } from "./ChatModal";
import { SoundToTextButton } from "./SidebarButtons/SoundToTextButton";

interface Props {
  className?: string;
  menuItems: MenuItem[] | null;
  defaultName?: string;
  selectedId?: string | null;
  onSelect: (id: string) => void;
  onAdd?: () => void;
  onDelete?: (id: string, name: string) => void;
  isSendDisabled?: boolean;
  isLoading?: boolean;
  hasHeader?: boolean;
}

export type MenuItem = {
  id: string;
  name: string;
};

export const AppSidebar: React.FC<Props> = ({
  className,
  menuItems,
  defaultName,
  selectedId,
  onSelect,
  onAdd,
  onDelete,
  isSendDisabled,
  isLoading,
  hasHeader = true,
}) => {
  const [askGptModalOpen, setAskGptModalOpen] = useState<boolean>(false);
  const [audioTranscriptionModalOpen, setAudioTranscriptionModalOpen] =
    useState<boolean>(false);

  const handleAskGPTModalOpen = () => {
    setAskGptModalOpen(true);
  };

  const handleAudioTranscriptionModalOpen = () => {
    setAudioTranscriptionModalOpen(true);
  };

  const handleAskGPTModalClose = () => {
    setAskGptModalOpen(false);
  };

  const handleAudioTranscriptionModalClose = () => {
    setAudioTranscriptionModalOpen(false);
  };

  return (
    <>
      <aside className={classNames(styles.sidebar, className)}>
        {hasHeader && (
          <div className={styles["sidebar-header"]}>
            {onAdd && (
              <div className={styles["button-container"]}>
                <AddButton onAdd={onAdd} isDisabled={isSendDisabled} />
              </div>
            )}
          </div>
        )}
        <div className={styles["sidebar-body"]}>
          <ul className={styles.list}>
            {isLoading && <AppLoading className={styles.loader} small />}
            {menuItems?.map((item) => (
              <li
                key={item.id}
                className={classNames(styles["list-item"], {
                  [styles.selected]: item.id === selectedId,
                  [styles.arrow]: item.id === selectedId && !onDelete,
                  [styles["select-bg"]]: item.id === selectedId,
                })}
              >
                <span
                  className={classNames(styles["item-name"], {
                    [styles.disabled]: isSendDisabled,
                  })}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    !isSendDisabled && item.id && onSelect(item.id);
                  }}
                >
                  {!item.name ? defaultName : item.name}
                </span>
                {onDelete && (
                  <IconButton
                    className={classNames(styles["delete-button"], {
                      [styles.disabled]: isSendDisabled,
                    })}
                    onClick={() => onDelete(item.id, item.name)}
                  >
                    <TrashIcon />
                  </IconButton>
                )}
              </li>
            ))}
          </ul>
        </div>
        <SidebarFooter>
          <AskGPTButton openAskGPT={handleAskGPTModalOpen} />
          <SoundToTextButton
            openSoundToText={handleAudioTranscriptionModalOpen}
          />
        </SidebarFooter>
      </aside>
      <AudioTranscription
        open={audioTranscriptionModalOpen}
        onClose={handleAudioTranscriptionModalClose}
        closeModal={handleAudioTranscriptionModalClose}
      />
      <ChatModal
        open={askGptModalOpen}
        closeModal={handleAskGPTModalClose}
        onClose={handleAskGPTModalClose}
      />
    </>
  );
};
