import type { FC } from "react";
import styles from "./styles.module.css";
import { UserMessage } from "./UserMessage";
import { SystemMessage } from "./SystemMessage";
import type {TextGenerationResponseMessage} from "../../../../../services/api/methodsTypes";

interface Props {
  messages: TextGenerationResponseMessage[];
  isLoading?: boolean;
}

export const DisplayedMessages: FC<Props> = ({ messages, isLoading }) => {
  return (
    <div className={styles.wrapper}>
      {messages?.length ? (
        <>
          {messages.map((message, index) => {
            if (message.role === "user") {
              return (
                <UserMessage
                  key={`${message.role}-${index}`}
                  userMessage={message.content}
                />
              );
            }
            if (message.role === "system") {
              return (
                <SystemMessage
                  key={`${message.role}-${index}`}
                  systemMessage={message.content}
                />
              );
            }
          })}
        </>
      ) : (
        <div className={styles.empty}>Пустой чат</div>
      )}
      {isLoading && <div className={styles.loading}>Загрузка сообщения...</div>}
    </div>
  );
};
