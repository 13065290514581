import type {
  ToggleButtonGroupProps} from "@mui/material";
import {
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { EyeIcon } from "../../../../shared/icons/EyeIcon";
import { EyeInvisibleIcon } from "../../../../shared/icons/EyeInvisibleIcon";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends ToggleButtonGroupProps {
  visibility: boolean;
  handleVisibility: (
    event: React.MouseEvent<HTMLElement>,
    newVisibility: boolean | null
  ) => void;
}

export const MainToggleButton: React.FC<Props> = ({
  visibility,
  handleVisibility,
  ...props
}) => {
  return (
    <ToggleButtonGroup
      value={visibility}
      exclusive
      onChange={handleVisibility}
      {...props}
    >
      <ToggleButton
        value={true}
        className={classNames(styles["toggle-button"], {
          [styles.selected]: visibility === true,
        })}
      >
        <EyeIcon />
        <span>Показать</span>
      </ToggleButton>
      <ToggleButton
        value={false}
        className={classNames(styles["toggle-button"], {
          [styles.selected]: visibility === false,
        })}
      >
        <EyeInvisibleIcon />
        <span>Скрыть</span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
