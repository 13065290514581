import { AppIconButton } from "../../../../../shared/components/AppIconButton";
import { AppInputBase } from "../../../../../shared/components/AppInputBase";
import { TrashIcon } from "../../../../../shared/icons/TrashIcon";
import type { ChangedInput } from "../..";
import classNames from "classnames";
import styles from "./styles.module.css";
import type {inputValue} from "../../../../Embeddings/Sidebar";

interface Props {
  className?: string;
  value: inputValue;
  cardId: string;
  onDeleteInput: (cardId: string, inputId: string) => void;
  onInputChange: (
    changedInput: ChangedInput,
    cardId: string,
    inputId: string,
    value: string
  ) => void;
}

export const ClassInputContainer: React.FC<Props> = ({
  className,
  value,
  cardId,
  onDeleteInput,
  onInputChange,
}) => {
  return (
    <div className={styles["input-container"]}>
      <AppInputBase
        className={classNames(styles.input, className)}
        small
        rows={undefined}
        placeholder="Пример"
        onChange={(e) =>
          onInputChange("value", cardId, value.id, e.target.value)
        }
      />
      <AppIconButton onClick={() => onDeleteInput(cardId, value.id)}>
        <TrashIcon />
      </AppIconButton>
    </div>
  );
};
