import {deleteAssistantAction} from "../../../features/Assistants/redux/actions";
import {useAppDispatch} from "../../../services/redux/hooks/use-dispatch";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export const useDeleteAssistantModal = () => {
  const dispatch = useAppDispatch();
  const [deleteAssistantInfo, setDeleteAssistantInfo] = useState({});
  const [isDeleteAssistantModalOpen, setIsDeleteAssistantModalOpen] = useState(false);
  const navigate = useNavigate();

  const openDeleteAssistantModal = (id: string, name: string) => {
    setDeleteAssistantInfo({ id, name });
    setIsDeleteAssistantModalOpen(true);
  };

  const closeDeleteAssistantModal = () => {
    setDeleteAssistantInfo({});
    setIsDeleteAssistantModalOpen(false);
  };

  const handleAssistantDelete = (id: string) => {
    closeDeleteAssistantModal();
    dispatch(deleteAssistantAction({id, navigate}));
  };

  return {
    deleteAssistantInfo,
    isDeleteAssistantModalOpen,
    handleAssistantDelete,
    openDeleteAssistantModal,
    closeDeleteAssistantModal,
  };
};