import type { StylistStateType } from "../types";

export function stylistReducer(state: StylistStateType, action: any) {
  switch (action.type) {
    case "SET_ASSISTANT_LIST":
      return { ...state, assistantList: action.payload };
    case "SET_SELECTED_ASSISTANT": {
      state.postObject.model = action.payload.model;
      state.postObject.temperature = action.payload.temperature;
      state.postObject.messages[0].content = action.payload.instructions;
      return {
        ...state,
        selectedAssistant: action.payload,
      };
    }
    case "SET_ORIGINAL_TEXT": {
      state.postObject.messages[1].content = action.payload;
      return { ...state, originalText: action.payload };
    }
    case "SET_RESULT": {
      const instructions = state.selectedAssistant?.instructions ?? "{$result}";
      state.postObject.messages[0].content = instructions.replace(
        "{$result}",
        action.payload
      );
      return {
        ...state,
        result: action.payload.toString(),
      };
    }
    case "SET_DATE_UPDATE":
      return { ...state, dateUpdate: action.payload };
    default:
      return state;
  }
}
