import { useEffect } from "react";
import { ASSISTANTS_WITH_THREADS } from "../../../services/api/constants";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import {
  selectAssistantFunctions,
  selectIsMessageLoading,
  selectMessages,
  selectModels,
  selectSelectedAssistant,
  selectStores,
  selectThread,
} from "../redux/selectors";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import {
  createThreadAction,
  getAssistantFunctionsAction,
  getMessagesAction,
} from "../redux/actions";
import { setThreadAction } from "../redux/slice";
import { multiUserApi } from "../../../shared/utils/multiUserFunctions";

export const useAssistant = () => {
  const dispatch = useAppDispatch();
  const selectedAssistant = useAppSelector(selectSelectedAssistant)!;
  const models = useAppSelector(selectModels);
  const functions = useAppSelector(selectAssistantFunctions);
  const isMessageLoading = useAppSelector(selectIsMessageLoading);
  const stores = useAppSelector(selectStores);
  const thread = useAppSelector(selectThread);
  const messages = useAppSelector(selectMessages);
  const localStorageAssistantsWithThreadJSON: string =
    localStorage.getItem(ASSISTANTS_WITH_THREADS) || "{}";
  const localStorageAssistantsWithThread: Record<string, string> = JSON.parse(
    localStorageAssistantsWithThreadJSON
  );

  useEffect(() => {
    if (selectedAssistant) {
      if (localStorageAssistantsWithThread[selectedAssistant.id!]) {
        dispatch(
          setThreadAction({
            id: localStorageAssistantsWithThread[selectedAssistant.id!],
            object: "thread",
          })
        );
        dispatch(getMessagesAction());
      } else {
        dispatch(createThreadAction());
      }

      dispatch(getAssistantFunctionsAction(selectedAssistant.id!));
    }
  }, [dispatch, selectedAssistant?.id]);

  return {
    selectedAssistant,
    thread,
    messages,
    models,
    stores,
    functions,
    isMessageLoading,
  };
};
