import axios from "axios";
import { multiUserApi } from "../../../shared/utils/multiUserFunctions";

let APP_VECTOR_TOOLS_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_VECTOR_TOOLS_API_URL = process.env.REACT_APP_VECTOR_TOOLS_URL || "/";
} else {
  APP_VECTOR_TOOLS_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_VECTOR_TOOLS_URL;
}

export let appVectorToolsApi = axios.create({
  baseURL: APP_VECTOR_TOOLS_API_URL,
});

export const recreateAppVectorToolsApi = (
  setUserInfo: (value: null) => void
) => {
  appVectorToolsApi = axios.create({
    baseURL: APP_VECTOR_TOOLS_API_URL,
  });

  appVectorToolsApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${multiUserApi.getUserKey(
        "access_token"
      )}`;
      request.headers["X-Keycloak-Token"] =
        multiUserApi.getUserKey("access_token");
    }
    return request;
  });

  appVectorToolsApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        if (multiUserApi.parseToArray().length > 1) {
          multiUserApi.removeUser();
          window.location.reload();
        } else {
          multiUserApi.removeUser();
          setUserInfo(null);
        }
      }
      return Promise.reject(error);
    }
  );
};
