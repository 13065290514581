import type { MenuItemProps } from "@mui/material";
import { MenuItem } from "@mui/material";
import { forwardRef } from "react";

export const AppMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ children, ...props }, ref) => {
    return (
      <MenuItem ref={ref} {...props}>
        {children}
      </MenuItem>
    );
  }
);
