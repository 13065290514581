import type { ToggleButtonGroupProps } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props extends ToggleButtonGroupProps {
  className?: string;
}

export const AppToggleButtonGroup: React.FC<Props> = ({
  className,
  ...props
}) => {
  return (
    <ToggleButtonGroup
      className={classNames(styles.group, className)}
      {...props}
    >
      {props.children}
    </ToggleButtonGroup>
  );
};
