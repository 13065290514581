import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AssistantsState } from "./types";
import {
  createNewAssistantReducerBuilder,
  createThreadReducerBuilder,
  deleteAssistantReducerBuilder,
  getAssistantFunctionsReducerBuilder,
  getAssistantsReducerBuilder,
  getMessagesReducerBuilder,
  getModelsReducerBuilder,
  getStoresReducerBuilder,
  postCompMessageReducerBuilder,
  saveUpdatedAssistantReducerBuilder,
  updateMessagesReducerBuilder,
} from "./reducers";
import type {
  AssistantFunction,
  AssistantInfo,
  Message,
  ThreadInfo,
} from "../../../services/api/methodsTypes";

const initialState: AssistantsState = {
  assistants: [],
  selectedAssistant: null,
  stores: [],
  models: [],
  isModelsLoading: false,
  isStoresLoading: false,
  isAssistantsLoading: false,
  isThreadLoading: false,
  isFunctionsLoading: false,
  isMessageLoading: false,
  thread: null,
  assistantFunctions: [],
  messages: [],
  isSendDisabled: false,
};

const assistantsSlice = createSlice({
  name: "assistants",
  initialState,
  reducers: {
    setSelectedAssistantAction: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedAssistant =
        state.assistants.find((item) => item.id === action.payload) || null;
      state.assistantFunctions = [];
    },
    updateSelectedAssistantAction: (
      state,
      action: PayloadAction<AssistantInfo>
    ) => {
      state.assistants = state.assistants.map((assistant: AssistantInfo) => {
        if (action.payload.id === assistant.id) {
          return action.payload;
        } else {
          return assistant;
        }
      });
      state.selectedAssistant = action.payload;
    },
    setFunctionsAction: (state, action: PayloadAction<AssistantFunction[]>) => {
      state.assistantFunctions = action.payload;
    },
    setAssistantsAction: (state, action: PayloadAction<AssistantInfo[]>) => {
      state.assistants = action.payload;
    },
    addMessageAction: (state, action: PayloadAction<Message>) => {
      state.messages = [...state.messages, action.payload];
    },
    setIsSendDisabledAction: (state, action: PayloadAction<boolean>) => {
      state.isSendDisabled = action.payload;
    },
    setIsMessageLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isMessageLoading = action.payload;
    },
    clearMessagesAction: (state) => {
      state.messages = [];
    },
    setThreadAction: (state, action: PayloadAction<ThreadInfo | null>) => {
      state.thread = action.payload;
    },
    clearAssistantsStateAction: () => initialState,
  },
  extraReducers: (builder) => {
    getAssistantsReducerBuilder(builder);
    getModelsReducerBuilder(builder);
    getStoresReducerBuilder(builder);
    createNewAssistantReducerBuilder(builder);
    deleteAssistantReducerBuilder(builder);
    createThreadReducerBuilder(builder);
    getAssistantFunctionsReducerBuilder(builder);
    saveUpdatedAssistantReducerBuilder(builder);
    postCompMessageReducerBuilder(builder);
    getMessagesReducerBuilder(builder);
    updateMessagesReducerBuilder(builder);
  },
});

export const assistantsReducer = assistantsSlice.reducer;
export const {
  setSelectedAssistantAction,
  updateSelectedAssistantAction,
  setFunctionsAction,
  clearMessagesAction,
  addMessageAction,
  setThreadAction,
  setIsSendDisabledAction,
  setIsMessageLoadingAction,
  setAssistantsAction,
  clearAssistantsStateAction,
} = assistantsSlice.actions;
