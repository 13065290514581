// Если есть propery object, то нам пришел OpenAiModelArray
// Если нет, то нам пришел CompModel
import type {
  ModelsArray,
  OpenAiModelArray,
} from "../../services/api/methodsTypes";

const isOpenAiModelArray = (res: any): res is OpenAiModelArray => {
  return res.hasOwnProperty("object") && res.object === "list";
};

export const transformModels = (
  models: ModelsArray | OpenAiModelArray
): ModelsArray => {
  if (isOpenAiModelArray(models)) {
    return {
      models: models.data.map((model) => ({
        friendly_name: model.id,
        model_name: model.id,
      })),
    };
  }

  return models;
};
