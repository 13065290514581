import styles from "./styles.module.css";
import { useProfile } from "../../../services/profile";
import { ResizableWrapper } from "../../../shared/components/ResizableWrapper";
import { useAssistant } from "../hooks/use-assistants";
import { useAppDispatch } from "../../../services/redux/hooks/use-dispatch";
import {
  addMessageAction,
  clearMessagesAction,
  setFunctionsAction,
  setIsMessageLoadingAction,
  setIsSendDisabledAction,
} from "../redux/slice";
import type {
  AssistantFunctionRunOutput,
  AssistantFunctionRunPostObjType,
  AssistantFunctionToolOutput,
  AssistantInfo,
  AssistantNewFunctionType,
  AssistantToolsObject,
  FilePurpose,
  MessageContent,
  RunInfo,
  UploadedFile,
} from "../../../services/api/methodsTypes";
import {
  createThreadAction,
  postCompMessageAction,
  saveUpdatedAssistantAction,
  updateMessagesAction,
} from "../redux/actions";
import { useRef, useState } from "react";
import {
  addAssistantFunction,
  createRun,
  getAssistantFunctionSchema,
  getRunStatus,
  runAssistantFunction,
  submitAssistantToolOutputs,
  uploadFile,
} from "../../../services/api/methods";
import { showError } from "../../../shared/utils/showError/showError";
import { AppNewFunctionDialog } from "../../../shared/components/AppDialog/AppNewFunctionDialog";
import { useAppSelector } from "../../../services/redux/hooks/use-selector";
import { selectIsSendDisabled } from "../redux/selectors";
import { AssistantSettings } from "./AssistantSettings";
import { AssistChat } from "./AssistChat";
import { multiUserApi } from "../../../shared/utils/multiUserFunctions";

export const Assistant = () => {
  const dispatch = useAppDispatch();
  const {
    selectedAssistant,
    thread,
    messages,
    models,
    stores,
    isMessageLoading,
    functions,
  } = useAssistant();
  const [message, setMessage] = useState<string>("");
  const [attachedFile, setAttachedFile] = useState<UploadedFile | null>(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const isSendDisabled = useAppSelector(selectIsSendDisabled);
  const [customLoadingMessage, setCustomLoadingMessage] = useState("");
  const [isNewFunctionModalOpen, setIsNewFunctionModalOpen] = useState(false);
  const [isRunTimedOut, setIsRunTimedOut] = useState(false);
  const runTimerRef = useRef<NodeJS.Timeout | null>(null);
  const runStatusCheckTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { userInfo } = useProfile();

  const checkRunStatus = (run: RunInfo) => {
    if (thread && !isRunTimedOut) {
      if (run.status === "in_progress" || run.status === "queued") {
        runStatusCheckTimerRef.current = setTimeout(() => {
          getRunStatus(thread.id, run?.id).then(checkRunStatus);
        }, 1000);

        return () => {
          if (runStatusCheckTimerRef.current)
            clearTimeout(runStatusCheckTimerRef.current);
        };
      }
      if (run.status === "requires_action") {
        // выполняем функции
        updateRunWithAssistantFunctions(run);
      }
      if (run.status === "completed") {
        dispatch(updateMessagesAction())
          .unwrap()
          .then(() => {
            if (runTimerRef.current) {
              clearTimeout(runTimerRef.current);
            }
          })
          .finally(() => dispatch(setIsSendDisabledAction(false)));
      }
      if (run.status === "failed") {
        showError("Что-то пошло не так, попробуйте отправить сообщение снова");
        dispatch(setIsSendDisabledAction(false));
        if (runTimerRef.current) {
          clearTimeout(runTimerRef.current);
        }
      }
    }
  };

  const handleSendMessage = () => {
    if (!message && !attachedFile) {
      return;
    }

    const messageObj = {
      role: "user",
      content: [
        {
          type: "text",
          text: message,
        },
      ],
    } as any;

    if (attachedFile) {
      const imageExtensions = [".png", ".jpg", ".jpeg", ".webp"];
      imageExtensions.forEach((ext) => {
        if (attachedFile.filename.endsWith(ext)) {
          messageObj.content = [
            {
              type: "text",
              text: message,
            },
            {
              type: "image_file",
              image_file: { file_id: attachedFile.id },
            },
          ];
        }
      });

      messageObj.attachments = [
        {
          file_id: attachedFile.id,
          tools: [{ type: "code_interpreter" }],
        },
      ];
      messageObj.metadata = {
        [attachedFile.id]: attachedFile.filename,
      };
      setAttachedFile(null);
    }
    dispatch(setIsSendDisabledAction(true));
    dispatch(
      addMessageAction({
        ...messageObj,
        id: Math.random().toString(),
        content: [
          ...messageObj.content.filter(
            (item: MessageContent) => item.type !== "text"
          ),
          {
            type: "text",
            text: {
              value: message,
            },
          },
        ],
      } as any)
    );
    dispatch(postCompMessageAction(messageObj))
      .unwrap()
      .then(() => {
        const runObj = {
          assistant_id: selectedAssistant?.id!,
        };
        createRun(thread?.id!, runObj)
          .then((run) => {
            checkRunStatus(run);
            // Таймаут рана
            runTimerRef.current = setTimeout(() => {
              showError("Время ожидания ответа истекло, попробуйте ещё раз");
              dispatch(setIsSendDisabledAction(false));
              setIsRunTimedOut(true);
            }, 120000);
          })
          .catch((error) => {
            dispatch(setIsSendDisabledAction(false));
            dispatch(setIsMessageLoadingAction(false));
            console.error(error);
          });
      })
      .catch((error) => {
        dispatch(setIsSendDisabledAction(false));
        dispatch(setIsMessageLoadingAction(false));
        console.error(error);
      });
    setMessage("");
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files[0]) return;
    setAttachedFile(null);
    setIsFileLoading(true);
    const postObj = {
      file: e.target.files[0],
      purpose: "assistants" as FilePurpose,
    };

    uploadFile(postObj)
      .then(setAttachedFile)
      .finally(() => setIsFileLoading(false));
  };

  const handleAddFunction = (
    url: string,
    friendlyName: string,
    isChecked: boolean
  ) => {
    const functionName = url.split("/").pop();
    if (!functionName) {
      showError("Не удалось получить название функции из ссылки");
      return;
    }
    if (functions.find((func) => func.function_name === functionName)) {
      showError("Эта функция уже добавлена для ассистента");
      return;
    }

    getAssistantFunctionSchema(selectedAssistant.id!, functionName)
      .then((res) => {
        const functionPostObj: AssistantNewFunctionType = {
          function_name: functionName,
          endpoint: url,
          credentials: isChecked,
          friendly_name: friendlyName,
          asst_name: selectedAssistant.id!,
        };

        addAssistantFunction(functionPostObj).then(() => {
          const functionInfoObject: AssistantToolsObject = {
            type: "function",
            function: res.schema,
          };
          const updatedAssistant: AssistantInfo = {
            ...selectedAssistant,
            tools: [...selectedAssistant.tools, functionInfoObject],
          };
          dispatch(
            setFunctionsAction([
              ...functions,
              {
                function_name: functionName,
                friendly_name: friendlyName,
                selected: true,
              },
            ])
          );
          saveAssistant(updatedAssistant);
        });
      })
      .catch(() => {
        showError("Функция с таким url не найдена");
      });
  };

  const updateRunWithAssistantFunctions = (run: RunInfo) => {
    const selectedFunction = functions.find(
      (func) =>
        func.function_name ===
        run.required_action.submit_tool_outputs.tool_calls[0].function.name
    )?.friendly_name;
    setCustomLoadingMessage(`Выполняется функция "${selectedFunction}"`);
    const promises: Promise<{
      output: AssistantFunctionRunOutput;
      toolId: string;
    }>[] = [];
    const assistantMessageId = selectedAssistant.id;
    const messageThreadId = thread?.id;

    run.required_action.submit_tool_outputs.tool_calls.forEach((tool) => {
      const postObject: AssistantFunctionRunPostObjType = {
        params: JSON.parse(tool.function.arguments),
        credentials: {
          openai_key: null,
          base_url: userInfo?.thread_url || "",
          asst_id: assistantMessageId!,
          thread_id: messageThreadId!,
        },
      };

      promises.push(
        runAssistantFunction(tool.function.name, postObject).then((res) => ({
          output: res,
          toolId: tool.id,
        }))
      );
    });

    Promise.all(promises).then((res) => {
      setCustomLoadingMessage("Отправляются результаты");
      const payloads: AssistantFunctionToolOutput[] = [];
      res.forEach(({ output, toolId }) => {
        const functionOutput = output.function_output;
        const payload = {
          tool_call_id: toolId,
          output: functionOutput ?? "Не найдено, попробовать позже",
        };
        payloads.push(payload);
      });

      const postObj = { tool_outputs: payloads };
      submitAssistantToolOutputs(messageThreadId!, run.id, postObj).then(
        (response) => {
          checkRunStatus(response);
          setCustomLoadingMessage("");
        }
      );
    });
  };

  const openCreateFunctionModal = () => setIsNewFunctionModalOpen(true);
  const closeCreateFunctionModal = () => setIsNewFunctionModalOpen(false);

  const handleClearChat = () => {
    setMessage("");
    dispatch(clearMessagesAction());
    dispatch(createThreadAction());
  };

  const saveAssistant = (assistant: AssistantInfo) => {
    dispatch(saveUpdatedAssistantAction(assistant));
  };

  return (
    <>
      <ResizableWrapper
        hasButton={true}
        leftBlock={
          <AssistantSettings
            models={models}
            selectedAssistant={selectedAssistant!}
            saveAssistant={saveAssistant}
            stores={stores}
            functions={functions}
            openCreateFunctionModal={openCreateFunctionModal}
            isSendDisabled={isSendDisabled}
          />
        }
        rightBlock={
          <AssistChat
            className={styles.chat}
            userMessage={message}
            setUserMessage={setMessage}
            handleSend={handleSendMessage}
            handleFileUpload={handleFileUpload}
            isDisabled={isSendDisabled}
            handleClearChat={handleClearChat}
            messages={messages}
            attachedFile={attachedFile}
            setAttachedFile={setAttachedFile}
            isFileLoading={isFileLoading}
            isMessageLoading={isMessageLoading || isSendDisabled}
            customLoadingMessage={customLoadingMessage}
          />
        }
        leftBlockSize={40}
        rightBlockSize={60}
        rightBlockMinSize={50}
      />
      <AppNewFunctionDialog
        dialogOpen={isNewFunctionModalOpen}
        handleDialogClose={closeCreateFunctionModal}
        handleAddFunction={handleAddFunction}
      />
    </>
  );
};
