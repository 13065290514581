import type { AppIconProps } from "../IconTypes";

export const UserIcon: React.FC<AppIconProps> = ({
  width = 16,
  height = 16,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6665 13.3334V12.1482C4.6665 11.5196 4.91624 10.9167 5.36077 10.4721C5.8053 10.0276 6.40821 9.77786 7.03687 9.77786H9.40725C10.0359 9.77786 10.6388 10.0276 11.0833 10.4721C11.5279 10.9167 11.7776 11.5196 11.7776 12.1482V13.3334M5.85169 5.03712C5.85169 5.66578 6.10142 6.26869 6.54595 6.71322C6.99049 7.15775 7.5934 7.40749 8.22206 7.40749C8.85072 7.40749 9.45363 7.15775 9.89817 6.71322C10.3427 6.26869 10.5924 5.66578 10.5924 5.03712C10.5924 4.40846 10.3427 3.80554 9.89817 3.36101C9.45363 2.91648 8.85072 2.66675 8.22206 2.66675C7.5934 2.66675 6.99049 2.91648 6.54595 3.36101C6.10142 3.80554 5.85169 4.40846 5.85169 5.03712Z"
        stroke="#002941"
        strokeOpacity="0.36"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
