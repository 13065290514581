import type { FC } from "react";
import { AppIconButton } from "../../../../shared/components/AppIconButton";
import { BigXIcon } from "../../../../shared/icons/BigXIcon";
import styles from "./styles.module.css";
import type { ButtonProps } from "@mui/material";
import classNames from "classnames";

interface Props extends ButtonProps {
  title: string;
  hasCloseButton?: boolean;
  className?: string;
}

export const ModalBasicHeader: FC<Props> = ({
  title,
  hasCloseButton,
  className,
  ...props
}) => {
  return (
    <div className={classNames(styles["modal-header"], className)}>
      <h2 className={styles["modal-title"]}>{title}</h2>
      {hasCloseButton && (
        <AppIconButton className={styles["close-button"]} {...props}>
          <BigXIcon />
        </AppIconButton>
      )}
    </div>
  );
};
