let classificatorFolderId = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorFolderId =
    process.env.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID || "";
} else {
  classificatorFolderId =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_FOLDER_ID;
}

let classificatorKey = "";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorKey = process.env.REACT_APP_CLASSICATOR_TOOLS_API_KEY || "";
} else {
  classificatorKey =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_API_KEY;
}

let classificatorModel = "yandexgpt/latest";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  classificatorModel = process.env.REACT_APP_CLASSICATOR_TOOLS_MODEL || "";
} else {
  classificatorModel =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_CLASSICATOR_TOOLS_MODEL;
}

let audioTranscriptionToken: string | undefined;
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  audioTranscriptionToken = process.env.REACT_APP_AUDIO_TRANSCRIPTION_TOKEN;
} else {
  audioTranscriptionToken =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_AUDIO_TRANSCRIPTION_TOKEN;
}

export const ASSISTANTS_WITH_THREADS = "ASSISTANTS_WITH_THREADS";
export const APP_MULTI_USER_API = "APP_MULTI_USER_API";

export {
  classificatorFolderId,
  classificatorKey,
  classificatorModel,
  audioTranscriptionToken,
};
