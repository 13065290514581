import { useEffect, useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { AppButton } from "../../../shared/components/AppButton";
import { TrashIcon } from "../../../shared/icons/TrashIcon";
import { AppLabel } from "../../../shared/components/AppLabel";
import { AppInput } from "../../../shared/components/AppInput";
import { AppIconButton } from "../../../shared/components/AppIconButton";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import { PaperclipIcon } from "../../../shared/icons/PaperclipIcon";
import {
  createStoreFile,
  updateStore,
  uploadFile,
} from "../../../services/api/methods";
import type { FilePurpose } from "../../../services/api/methodsTypes";
import type {
  DialogType,
  ShortFileData,
  StoreDataWithFiles,
} from "../../../pages/DatabasePage";
import { AppLoading } from "../../../shared/components/AppLoading";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  className?: string;
  storeData: StoreDataWithFiles[];
  setStoreData: React.Dispatch<React.SetStateAction<StoreDataWithFiles[]>>;
  selectedSidebarItem: string;
  setAddDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteDialogOpen: (id: string, type: DialogType) => void;
  isFileLoading: boolean;
  setIsFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

/* const testTokens = [
  {
    token: "1",
    token_name: "Токен 1",
  },
  {
    token: "2",
    token_name: "Токен 2",
  },
  {
    token: "3",
    token_name: "Токен 3",
  },
]; */

export const DatabaseMain: React.FC<Props> = ({
  className,
  storeData,
  setStoreData,
  selectedSidebarItem,
  setAddDialogOpen,
  handleDeleteDialogOpen,
  isFileLoading,
  setIsFileLoading,
}) => {
  // const [selectValue, setSelectValue] = useState("1");
  const [storeName, setStoreName] = useState("");
  const [displayedStoreName, setDisplayedStoreName] = useState(storeName);
  const [isDataModified, setIsDataModified] = useState(false);

  /*   const handleSelectChange = (e: any) => {
    setSelectValue(e.target.value);
  }; */

  const handleFileUpload = (e: any) => {
    if (!e.target.files[0]) return;
    setIsFileLoading(true);
    const postObj = {
      file: e.target.files[0],
      purpose: "assistants" as FilePurpose,
    };

    uploadFile(postObj)
      .then((res) => {
        createStoreFile(selectedSidebarItem, res.id).catch(console.log);
        const selectedStoreData = storeData.find(
          (item) => item.id === selectedSidebarItem
        );
        if (selectedStoreData) {
          const newFile: ShortFileData = {
            id: res.id,
            name: res.filename,
          };
          const oldFiles = selectedStoreData.files || [];
          selectedStoreData.files = [...oldFiles, newFile];
          const newStoreData = storeData.map((item) => {
            if (item.id === selectedSidebarItem) {
              return selectedStoreData;
            }
            return item;
          });

          setStoreData(newStoreData);
        }
      })
      .finally(() => {
        setIsFileLoading(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Обновление локального имени
      setStoreName(displayedStoreName);

      // Обновление данных на сервере
      if (!isDataModified) return;
      updateStore(selectedSidebarItem, { name: displayedStoreName });

      const newList = storeData.map((item) => {
        if (item.id === selectedSidebarItem) {
          return { ...item, name: displayedStoreName };
        }
        return item;
      });

      setStoreData(newList);

      setIsDataModified(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [displayedStoreName]);

  // Обновление данных при переключении базы знаний
  useEffect(() => {
    setStoreName(
      storeData.find((item) => item.id === selectedSidebarItem)?.name || ""
    );
    setDisplayedStoreName(
      storeData.find((item) => item.id === selectedSidebarItem)?.name || ""
    );
  }, [selectedSidebarItem]);

  return (
    <div className={classNames(styles.container, className)}>
      <AppUnderlinedTitle
        className={styles.title}
        leftContent={
          storeData.find((item) => item.id === selectedSidebarItem)?.name
        }
      >
        <AppButton
          onClick={() => handleDeleteDialogOpen(selectedSidebarItem, "store")}
          small
          buttonType="text"
          icon={<TrashIcon />}
        >
          Удалить
        </AppButton>
      </AppUnderlinedTitle>

      <AppLabel className={styles.label}>
        {"ID: " + selectedSidebarItem}
      </AppLabel>
      <AppInput
        value={displayedStoreName}
        onChange={(e) => {
          setDisplayedStoreName(e.target.value);
          setIsDataModified(true);
        }}
        className={styles.input}
        placeholder="Название базы знаний"
        status={
          storeData.find((item) => item.id === selectedSidebarItem)?.status
        }
      />

      {/* <AppLabel className={styles.label}>Выбор токеназера</AppLabel>
      <div className={styles["select-container"]}>
        <AppSelect
          disabled
          className={styles.select}
          value={selectValue}
          onChange={handleSelectChange}
          itemText={
            testTokens?.find((token) => token.token === selectValue)?.token_name
          }
        >
          {testTokens?.map((token, index) => (
            <AppSelectOption key={index} value={token.token}>
              {token.token_name}
            </AppSelectOption>
          ))}
        </AppSelect>
        <AppIconButton disabled buttonType="outlined" big>
          <ReloadIcon />
        </AppIconButton>
      </div> */}

      <AppUnderlinedTitle className={styles.title} leftContent="Файлы">
        <AppButton
          onClick={() => setAddDialogOpen(true)}
          buttonType="text"
          icon={<PlusIcon />}
          small
        >
          Пустой файл
        </AppButton>
      </AppUnderlinedTitle>
      {storeData.find((item) => item.id === selectedSidebarItem)?.files
        ?.length ? (
          <ul className={styles["file-list"]}>
            {storeData
            .find((item) => item.id === selectedSidebarItem)
            ?.files?.map((file) => (
              <li key={file.id} className={styles["file-list-item"]}>
                <div className={styles["file-name-container"]}>
                  <PaperclipIcon />
                  <span>{file.name}</span>
                </div>
                <AppIconButton
                  onClick={() => handleDeleteDialogOpen(file.id, "file")}
                >
                  <TrashIcon />
                </AppIconButton>
              </li>
            ))}
          </ul>
      ) : (
        <p className={styles.placeholder}>Нет файлов</p>
      )}
      {isFileLoading && <AppLoading small />}
      <AppButton
        component="label"
        className={styles["upload-button"]}
        buttonType="text"
        icon={<PlusIcon />}
      >
        <input
          className={styles["upload-input"]}
          type="file"
          onChange={handleFileUpload}
          onClick={(e: any) => {
            e.target.value = null;
          }}
        />
        Загрузить
      </AppButton>

      {/* <AppUnderlinedTitle className={styles.title} title="Каталог" />
      <div className={styles["catalog-item-container"]}>
        <AppButton
          disabled
          className={styles["catalog-button"]}
          buttonType="text"
        >
          Каталог.xlx
        </AppButton>
        <AppIconButton disabled>
          <TrashIcon />
        </AppIconButton>
      </div>
      <AppButton disabled buttonType="outlined" small>
        Добавить из базы данных
      </AppButton> */}
    </div>
  );
};
