import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAssistant,
  createThread,
  deleteAssistant,
  getAssistantFunctions,
  getAssistants,
  getCompModels,
  getFileContent,
  getMessages,
  getStores,
  postCompMessage,
  updateAssistant,
} from "../../../services/api/methods";
import type { RootState } from "../../../services/redux/types";
import type {
  AssistantInfo,
  AssistantList,
  Message,
  MessageList,
} from "../../../services/api/methodsTypes";
import { addSelectedFieldToFunctions } from "../../../shared/utils/assistantPageFunctions";
import { ASSISTANTS_WITH_THREADS } from "../../../services/api/constants";
import type { NavigateFunction } from "react-router-dom";
import {
  appRouterPaths,
  getAssistantPagePath,
} from "../../../services/navigation/urls";
import {
  clearMessagesAction,
  setAssistantsAction,
  setSelectedAssistantAction,
  setThreadAction,
} from "./slice";

export const getAssistantsAction = createAsyncThunk(
  "assistants/getAssistants",
  async () => {
    try {
      const response = await getAssistants();
      const listWithChangedResponseFormat: AssistantList = {
        ...response,
        data: response.data.map((item) => {
          if (
            item.response_format === "auto" ||
            (item.response_format as any).type === "text"
          ) {
            return {
              ...item,
              response_format: "auto",
            };
          }
          return {
            ...item,
            response_format: "json_object",
          };
        }),
      };

      return listWithChangedResponseFormat;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getCompModelsAction = createAsyncThunk(
  "assistants/getModels",
  async () => {
    try {
      return await getCompModels();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getStoresAction = createAsyncThunk(
  "assistants/getStores",
  async () => {
    try {
      return await getStores();
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const deleteAssistantAction = createAsyncThunk(
  "assistants/deleteAssistant",
  async (body: { id: string; navigate: NavigateFunction }, thunkAPI) => {
    try {
      const { navigate, id } = body;
      const { assistants, selectedAssistant } = (
        thunkAPI.getState() as RootState
      ).assistantsState;
      const localStorageAssistantsWithThreadJSON: string =
        localStorage.getItem(ASSISTANTS_WITH_THREADS) || "{}";
      const localStorageAssistantsWithThread: Record<string, string> =
        JSON.parse(localStorageAssistantsWithThreadJSON);
      const nextLocalStorageAssistantsWithThread = {
        ...localStorageAssistantsWithThread,
        [id]: undefined,
      };
      const nextLocalStorageValueJSON = JSON.stringify(
        nextLocalStorageAssistantsWithThread
      );
      localStorage.setItem(ASSISTANTS_WITH_THREADS, nextLocalStorageValueJSON);

      const nextAssistants = assistants.filter(
        (assistant) => assistant.id !== id
      );

      thunkAPI.dispatch(setAssistantsAction(nextAssistants));

      if (!nextAssistants[0]) {
        navigate(appRouterPaths.assistants);
        thunkAPI.dispatch(setSelectedAssistantAction(null));
        thunkAPI.dispatch(setThreadAction(null));
        thunkAPI.dispatch(clearMessagesAction());
      } else if (selectedAssistant?.id === id) {
        thunkAPI.dispatch(setSelectedAssistantAction(nextAssistants[0].id!));
        thunkAPI.dispatch(setThreadAction(null));
        thunkAPI.dispatch(clearMessagesAction());
        navigate(getAssistantPagePath(nextAssistants[0].id!));
      }

      return await deleteAssistant(body.id);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createNewAssistantAction = createAsyncThunk(
  "assistants/createAssistant",
  async (_, thunkAPI) => {
    const { models, assistants } = (thunkAPI.getState() as RootState)
      .assistantsState;

    const newAssistant: AssistantInfo = {
      model: models[0].model_name,
      name: `Ассистент ${!assistants?.length ? 1 : assistants?.length + 1}`,
      instructions: "",
      description: "",
      metadata: {
        education_page: "custom_assistant",
        dialogs: "false",
      },
      tools: [{ type: "code_interpreter" }],
      temperature: 0.5,
    };

    try {
      return await createAssistant(newAssistant);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const createThreadAction = createAsyncThunk(
  "assistants/createThread",
  async (_, thunkAPI) => {
    const { selectedAssistant } = (thunkAPI.getState() as RootState)
      .assistantsState;

    try {
      const res = await createThread();

      const localStorageAssistantsWithThreadJSON: string =
        localStorage.getItem(ASSISTANTS_WITH_THREADS) || "{}";
      const localStorageAssistantsWithThread: Record<string, string> =
        JSON.parse(localStorageAssistantsWithThreadJSON);
      const nextLocalStorageValue = {
        ...localStorageAssistantsWithThread,
        [selectedAssistant!.id!]: res.id,
      };
      const nextLocalStorageValueJSON = JSON.stringify(nextLocalStorageValue);
      localStorage.setItem(ASSISTANTS_WITH_THREADS, nextLocalStorageValueJSON);

      return res;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const saveUpdatedAssistantAction = createAsyncThunk(
  "assistants/updateAssistant",
  async (body: AssistantInfo) => {
    try {
      const res = await updateAssistant({
        ...body,
        description: "",
        response_format:
          body.response_format === "auto"
            ? "auto"
            : {
                type: "json_object",
              },
      });

      return {
        ...res,
        response_format:
          res.response_format === "auto" ||
          (res.response_format as any).type === "text"
            ? "auto"
            : "json_object",
      };
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const getAssistantFunctionsAction = createAsyncThunk(
  "assistants/getFunctions",
  async (id: string, thunkAPI) => {
    try {
      const { selectedAssistant } = (thunkAPI.getState() as RootState)
        .assistantsState;
      const functions = await getAssistantFunctions(id);

      return addSelectedFieldToFunctions(functions, selectedAssistant!.tools);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const postCompMessageAction = createAsyncThunk(
  "assistants/postCompMessage",
  async (message: Message, thunkAPI) => {
    try {
      const { thread } = (thunkAPI.getState() as RootState).assistantsState;

      return await postCompMessage(thread?.id!, message);
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

const proccessLinkedFiles = (message: Message): Promise<Message> => {
  const linkedFiles = message.content[0]?.text.annotations;
  if (!linkedFiles.length) {
    return Promise.resolve(message);
  }

  const promises: Promise<[Blob, string]>[] = linkedFiles
    .filter((linkedFile) => linkedFile.file_path?.file_id)
    .map((linkedFile) => {
      const fileId = linkedFile.file_path.file_id;
      const fileName = linkedFile.text.slice(
        linkedFile.text.lastIndexOf("/") + 1
      );
      return getFileContent(fileId).then((blob) => [blob, fileName]);
    });

  return Promise.all(promises).then((res) => {
    let newMessageText: string = message.content[0]?.text.value || "";

    res.forEach(([blob, fileName]) => {
      const newUrl = URL.createObjectURL(blob);
      // TODO: Название файла стоит передавать в новом localAssistantMessage.fileName и
      // настроить правильное отображение файла в сообщениях AppChatMessage
      const urlWithFileName = `#downloadlink===${fileName}===${newUrl}`;
      // Находим индекс первой sandbox ссылки в сообщении
      const linkRegex = /\]\(sandbox:/;
      const match = newMessageText.match(linkRegex);
      const startLinkIndex = match?.index;
      if (!startLinkIndex) return;
      const endLinkIndex = newMessageText.indexOf(")", startLinkIndex);

      if (startLinkIndex !== -1 && endLinkIndex !== -1) {
        const newLink = newMessageText.substring(
          startLinkIndex + 2,
          endLinkIndex
        );
        newMessageText = newMessageText.replace(newLink, urlWithFileName);
      }
    });

    return {
      ...message,
      content: [
        {
          ...message.content[0],
          text: {
            ...message.content[0].text,
            value: newMessageText,
          },
        },
      ],
    };
  });
};

export const getMessagesAction = createAsyncThunk(
  "assistants/getMessages",
  async (_, thunkAPI) => {
    try {
      const { thread } = (thunkAPI.getState() as RootState).assistantsState;

      let res = await getMessages(thread?.id!);

      for (let i = 0; i < res.data.length; i++) {
        const modifiedMessage: Message = await proccessLinkedFiles(res.data[i]);
        res = {
          ...res,
          data: res.data.map((item) => {
            if (modifiedMessage.id === item.id) {
              return modifiedMessage;
            }
            return item;
          }),
        };
      }

      return res;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);

export const updateMessagesAction = createAsyncThunk(
  "assistants/updateMessages",
  async (_, thunkAPI) => {
    try {
      const { thread } = (thunkAPI.getState() as RootState).assistantsState;

      const res: MessageList = await getMessages(thread?.id!);
      const modifiedMessage: Message = await proccessLinkedFiles(
        res.data[res.data.length - 1]
      );
      const newMessageList: MessageList = {
        ...res,
        data: res.data.map((item) => {
          if (modifiedMessage.id === item.id) {
            return modifiedMessage;
          }
          return item;
        }),
      };

      return newMessageList;
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  }
);
