import { useEffect, useState } from "react";
import { AppButton } from "../../../shared/components/AppButton";
import { SidebarSelect } from "./SidebarSelect";
import { SidebarListItem } from "./SidebarListItem";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import { AnalyzeIcon } from "../../../shared/icons/AnalyzeIcon";
import type { SelectChangeEvent } from "@mui/material";
import type {
  Dimensions,
  Model,
  PostObjType,
} from "../../../services/api/methodsTypes";
import classNames from "classnames";
import styles from "./styles.module.css";

interface Props {
  models: Model[];
  isLoading: boolean;
  isInputFilled: boolean;
  onModeling: (postObj: PostObjType) => void;
  setIsModelSelected: (isModelSelected: boolean) => void;
  setIsInputFilled: (isInputFilled: boolean) => void;
}

export type inputValue = {
  id: string;
  value: string;
};

const startInputValues = [
  {
    id: String(Math.random()),
    value: "",
  },
  {
    id: String(Math.random()),
    value: "",
  },
  {
    id: String(Math.random()),
    value: "",
  },
];

export const Sidebar: React.FC<Props> = ({
  models,
  isLoading,
  isInputFilled,
  onModeling,
  setIsModelSelected,
  setIsInputFilled,
}) => {
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [inputValues, setInputValues] =
    useState<inputValue[]>(startInputValues);
  const [dimension, setDimension] = useState<Dimensions>(2);

  const handleSelectChange = (e: SelectChangeEvent<any>) => {
    setSelectedModel(e.target.value);
    setIsModelSelected(true);
  };

  const handleAddItem = () => {
    const newValue = {
      id: String(Math.random()),
      value: "",
    };
    setInputValues([...inputValues, newValue]);
  };

  const handleDeleteItem = (idToRemove: string) => {
    const newInputValues = inputValues.filter((item) => item.id !== idToRemove);
    setInputValues(newInputValues);
  };

  const handleInputChange = (id: string, nextValue: string) => {
    setInputValues((prevValues: inputValue[]) => {
      return prevValues.map((item) => {
        return id === item.id
          ? {
              id,
              value: nextValue,
            }
          : item;
      });
    });
  };

  // // Dimension selectButton logic
  // const handleDimensionChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newDimention: Dimensions
  // ) => {
  //   if (newDimention !== null) {
  //     setDimension(newDimention);
  //   }
  // };

  const handleModeling = () => {
    const postObj = {
      sentences: inputValues
        .filter((item) => item.value.trim() !== "")
        .map((item) => item.value),
      embedding_method: selectedModel,
      dimensions: dimension,
    };

    onModeling(postObj);
  };

  useEffect(() => {
    if (
      inputValues.filter((inputValue) => inputValue.value.trim() !== "")
        .length >= 3
    ) {
      setIsInputFilled(true);
    } else setIsInputFilled(false);
  }, [inputValues]);

  const isDisabled = selectedModel === "" || !isInputFilled;

  return (
    <aside className={styles.sidebar}>
      <div className={styles["top-container"]}>
        <div className={styles.values}>
          <h2 className={styles.title}>Модель</h2>
          <div className={styles["select-container"]}>
            <SidebarSelect
              className={styles.select}
              onChange={handleSelectChange}
              models={models}
              selectedModel={selectedModel}
            />
          </div>
          <h3 className={styles.title}>Значения</h3>
          <ul className={styles.list}>
            {inputValues.map((item) => (
              <SidebarListItem
                key={item.id}
                inputValueObj={item}
                onDelete={handleDeleteItem}
                onInputChange={handleInputChange}
              />
            ))}
          </ul>
          <AppButton
            className={styles["add-button"]}
            buttonType="text"
            icon={<PlusIcon />}
            onClick={handleAddItem}
          >
            Добавить
          </AppButton>
        </div>
      </div>
      <div className={styles["bottom-container"]}>
        <AppButton
          className={classNames(styles["analyze-button"], {
            [styles.disabled]: isDisabled || isLoading,
          })}
          buttonType="contained"
          icon={<AnalyzeIcon />}
          onClick={handleModeling}
          disabled={isDisabled || isLoading}
        >
          Моделировать
        </AppButton>
      </div>
    </aside>
  );
};
