import squares from "../../shared/assets/img/squares.png";
import logo from "../../shared/assets/img/logo_light.png";
import styles from "./styles.module.css";

export const NotFoundPage = () => {
  return (
    <div className={styles["page-layout"]}>
      <img className={styles.logo} src={logo} alt="Логотип Гуреев" />
      <div className={styles.title}>404 Страница не найдена</div>
      <img className={styles["background-squares"]} src={squares} alt="" />
    </div>
  );
};
