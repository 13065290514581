import type { ChangeEvent, FC} from "react";
import { Ref, RefObject, useEffect, useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import styles from "./styles.module.css";
import classNames from "classnames";
import { SelectBlock } from "../../../shared/components/SelectBlock";
import { SettingsSwitch } from "../../../shared/components/SelectBlock/SettingsSwitch";
import { StylistTextArea } from "../StylistTextArea";
import { AppButton } from "../../../shared/components/AppButton";
import type { SelectChangeEvent } from "@mui/material";
import type { SettingType } from "./types";
import { CopyButton } from "../../../shared/components/Buttons/CopyButton";
import type { AssistantInfo } from "../../../services/api/methodsTypes";

interface Props {
  selectedAssistantName: string;
  text: string;
  switchValue: SettingType;
  assistants: AssistantInfo[];
  onClick: () => void;
  onSelect: (e: SelectChangeEvent<unknown>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSwitch: () => void;
  isButtonDisabled: boolean;
  className?: string;
}

export const StylistMain: FC<Props> = ({
  selectedAssistantName,
  text,
  switchValue,
  assistants,
  onClick,
  onSelect,
  onChange,
  handleSwitch,
  isButtonDisabled,
  className,
}) => {
  const [minRows, setMinRows] = useState(4);

  useEffect(() => {
    const calculateMinRows = () => {
      const windowHeight = window.innerHeight;
      const rowHeight = 32;
      const rows = Math.floor(windowHeight / rowHeight);
      setMinRows(rows);
    };

    calculateMinRows();

    window.addEventListener("resize", calculateMinRows);

    return () => {
      window.removeEventListener("resize", calculateMinRows);
    };
  }, []);

  const getAssistantList = () => {
    const copyAssistants = [...assistants];
    const assistantList = copyAssistants.map((assistant) => {
      return { id: assistant.id, value: assistant.name };
    });
    return assistantList;
  };

  return (
    <div className={classNames(styles.main, className)}>
      <AppUnderlinedTitle className={styles.title} leftContent={"Ваш текст"} />
      <div className={styles["select-block-container"]}>
        <SelectBlock
          label={"Модель стилизации"}
          hasSettings={true}
          options={getAssistantList()}
          value={selectedAssistantName}
          itemText={selectedAssistantName}
          onChange={onSelect}
          placeholder="Выбрать"
        >
          <SettingsSwitch
            pickedValue={switchValue}
            switches={[
              { id: 1, switchName: "AUTO", switchValue: "auto" },
              { id: 2, switchName: "Вручную", switchValue: "вручную" },
            ]}
            onClick={handleSwitch}
          />
        </SelectBlock>
      </div>
      <div className={styles["text-area-container"]}>
        <StylistTextArea
          value={text}
          onChange={onChange}
          placeholder={"Исходное письмо"}
          minRows={minRows}
        />
        <CopyButton
          onClick={() => navigator.clipboard.writeText(text)}
          position="absolute"
        />
      </div>
      <AppButton
        buttonType="contained"
        disabled={isButtonDisabled}
        className={styles["save-button"]}
        onClick={onClick}
      >
        Стилизовать
      </AppButton>
    </div>
  );
};
