import type { AppIconProps } from "../IconTypes";

export const SoundTextIcon: React.FC<AppIconProps> = ({
  width = 16,
  height = 16,
  className,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66602 10.9396C1.66602 10.6854 1.76703 10.4415 1.94683 10.2617C2.12663 10.0819 2.37049 9.98088 2.62477 9.98088H3.10414C3.35842 9.98088 3.60228 10.0819 3.78208 10.2617C3.96188 10.4415 4.06289 10.6854 4.06289 10.9396V12.3778C4.06289 12.632 3.96188 12.8759 3.78208 13.0557C3.60228 13.2355 3.35842 13.3365 3.10414 13.3365H2.62477C2.37049 13.3365 2.12663 13.2355 1.94683 13.0557C1.76703 12.8759 1.66602 12.632 1.66602 12.3778V10.9396ZM1.66602 10.9396V9.5015C1.66602 8.4844 2.07006 7.50895 2.78926 6.78975C3.50846 6.07055 4.48391 5.6665 5.50102 5.6665C6.51812 5.6665 7.49357 6.07055 8.21277 6.78975C8.93197 7.50895 9.33602 8.4844 9.33602 9.5015V10.9396M9.33602 10.9396C9.33602 10.6854 9.235 10.4415 9.0552 10.2617C8.8754 10.0819 8.63154 9.98088 8.37727 9.98088H7.89789C7.64361 9.98088 7.39975 10.0819 7.21995 10.2617C7.04015 10.4415 6.93914 10.6854 6.93914 10.9396V12.3778C6.93914 12.632 7.04015 12.8759 7.21995 13.0557C7.39975 13.2355 7.64361 13.3365 7.89789 13.3365H8.37727C8.63154 13.3365 8.8754 13.2355 9.0552 13.0557C9.235 12.8759 9.33602 12.632 9.33602 12.3778V10.9396Z"
        stroke="#1D9F81"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.0035H12.1554C12.4412 15.0035 12.7153 14.89 12.9174 14.6878C13.1195 14.4857 13.2331 14.2116 13.2331 13.9258V10.3335M13.2331 10.3335L11.7962 11.7704M13.2331 10.3335L14.67 11.7704"
        stroke="#1D9F81"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.6748V7.6748H13.5M15 2.46353V1.6748H9V2.46353"
        stroke="#1D9F81"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
