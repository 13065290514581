import type { FC} from "react";
import { useState } from "react";
import { AppMenu } from "../../shared/components/AppMenu";
import { AccountMenuItem } from "./AccountMenuItem";
import { AppDialog } from "../../shared/components/AppDialog";
import { AuthForm } from "../../pages/LoginPage/AuthForm";
import type { MenuProps } from "@mui/material";
import { Divider } from "@mui/material";
import type { AuthUserType } from "../../services/api/methodsTypes";
import { multiUserApi } from "../../shared/utils/multiUserFunctions";
import styles from "./styles.module.css";
import { LogoutIcon } from "../../shared/icons/LogoutIcon";
import { AppIconButton } from "../../shared/components/AppIconButton";
import { AddUserButton } from "./AddUserButton";

interface Props extends MenuProps {
  logout: () => void;
  toggleItem?: () => void;
  userList?: AuthUserType[];
  handleCloseMenu: () => void;
}

export const AccountMenu: FC<Props> = ({
  logout,
  toggleItem,
  userList,
  handleCloseMenu,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleToggle = (user: string) => {
    multiUserApi.toggleUser(user);
    window.location.reload();
  };

  return (
    <>
      <AppMenu {...props}>
        {userList?.map((user) => (
          <div key={user.user_name} className={styles["item-wrapper"]}>
            <AccountMenuItem
              name={user.user_name}
              onClick={() => handleToggle(user.user_name)}
              className={styles["account-menu-item"]}
            />
            {user.isActive && (
              <AppIconButton
                onClick={logout}
                buttonType="text"
                className={styles.logout}
              >
                <LogoutIcon />
              </AppIconButton>
            )}
          </div>
        ))}
        <Divider />
        <AddUserButton
          addUser={() => setIsDialogOpen(true)}
          onClick={handleCloseMenu}
        />
      </AppMenu>
      <AppDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <AuthForm btnText="Добавить пользователя" formLocation="app" />
      </AppDialog>
    </>
  );
};
