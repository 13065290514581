import type { FC } from "react";
import styles from "../styles.module.css";
import type { ButtonProps } from "@mui/material";
import classNames from "classnames";
import type {SidebarButtonSize} from "../types";
import {AppButton} from "../../../../shared/components/AppButton";
import {SoundTextIcon} from "../../../../shared/icons/SoundTextIcon";

interface Props extends ButtonProps {
  buttonSize?: SidebarButtonSize;
  openSoundToText: () => void;
}

export const SoundToTextButton: FC<Props> = ({
  openSoundToText,
  buttonSize,
  ...props
}) => {
  return (
    <AppButton
      buttonType={"text"}
      onClick={openSoundToText}
      icon={<SoundTextIcon />}
      className={classNames(styles.button, {
        [styles["pd.block-16"]]: buttonSize === "middle",
        [styles["pd.block-10"]]: buttonSize === "small",
      })}
      {...props}
    >
      Распознать аудио
    </AppButton>
  );
};
