import type {
  FC,
  DragEvent,
  ReactNode,
  ChangeEvent} from "react";
import {
  useState,
  useEffect,
} from "react";
import styles from "./styles.module.css";
import type { DialogProps } from "@mui/material";
import type { AudioTranscriptionInfo, TranscriptionModeType } from "./types";
import { getProcessingResult, uploadAudiofile } from "./helpers";
import { UploadZone } from "./components/UploadZone";
import classNames from "classnames";
import { AudioTranscriptionLayout } from "./components/AudioTranscriotionLayout";
import { AUDIO_TRANSCRIPTION_STORAGE_INFO } from "./constants";
import {ModalBasic} from "../../../shared/components/ModalBasic";

interface Props extends DialogProps {
  closeModal?: () => void;
}

export const AudioTranscription: FC<Props> = ({ closeModal, ...props }) => {
  const [content, setContent] = useState<string | ReactNode>("");
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] =
    useState<TranscriptionModeType>("upload");
  const [progress, setProgress] = useState(0);
  const [controller, setController] = useState<AbortController | null>(null);

  useEffect(() => {
    const audioTranscriptionInfo: AudioTranscriptionInfo =
      JSON.parse(
        sessionStorage.getItem(AUDIO_TRANSCRIPTION_STORAGE_INFO) as string
      ) || {};
    if (sessionStorage.getItem(AUDIO_TRANSCRIPTION_STORAGE_INFO)) {
      setUploadStatus(audioTranscriptionInfo.status as TranscriptionModeType);
      setContent(audioTranscriptionInfo.text);
      setProgress(audioTranscriptionInfo.progress);
    }
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;
    const uploadFile = e?.target?.files?.[0];
    setUploadStatus("loading");
    setFileName(uploadFile.name as string);
    uploadAudiofile(uploadFile, setUploadStatus, setContent);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    const uploadFile = e.dataTransfer.files;
    setUploadStatus("loading");
    setFileName(uploadFile[0].name);

    uploadAudiofile(uploadFile[0], setUploadStatus, setContent);
  };

  const handleRecognize = () => {
    const newController = new AbortController();
    setController(newController);
    setUploadStatus("processing");
    setProgress(0);
    getProcessingResult(
      setUploadStatus,
      setContent,
      setProgress,
      newController.signal
    );
  };

  const handleAbort = () => {
    const audioTranscriptionInfo: AudioTranscriptionInfo = JSON.parse(
      sessionStorage.getItem(AUDIO_TRANSCRIPTION_STORAGE_INFO) as string
    );
    if (audioTranscriptionInfo) {
      audioTranscriptionInfo.progress = 0;
      audioTranscriptionInfo.status = "upload";
      audioTranscriptionInfo.text = "";
    }
    controller?.abort();
    setUploadStatus("upload");

    sessionStorage.setItem(
      AUDIO_TRANSCRIPTION_STORAGE_INFO,
      JSON.stringify(audioTranscriptionInfo)
    );
  };

  return (
    <ModalBasic
      title={"Расшифровка аудио"}
      hasCloseButton
      closeModal={closeModal}
      className={styles.modal}
      hasFooter={true}
      onOk={handleRecognize}
      onCancel={handleAbort}
      disabledOkBtn={!(uploadStatus === "ready" || uploadStatus === "failed")}
      {...props}
    >
      <div
        className={classNames(styles["modal-content"], {
          [styles["processing"]]: uploadStatus !== "upload",
        })}
      >
        {uploadStatus === "upload" && (
          <UploadZone dropHandler={handleDrop} onChange={handleFileChange} />
        )}
        {uploadStatus !== "upload" && (
          <AudioTranscriptionLayout
            status={uploadStatus}
            content={content}
            fileName={fileName}
            progress={progress}
          />
        )}
      </div>
    </ModalBasic>
  );
};
