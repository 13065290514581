import type { AppIconProps } from "../IconTypes";

export const SendMessageIcon: React.FC<AppIconProps> = ({
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="24" height="24" rx="6" fill="#6CD5BD" />
      <path
        d="M10.6667 13.3333L18 6M10.6667 13.3333L13 18C13.0293 18.0638 13.0763 18.1179 13.1353 18.1558C13.1944 18.1938 13.2632 18.2139 13.3334 18.2139C13.4036 18.2139 13.4723 18.1938 13.5314 18.1558C13.5905 18.1179 13.6375 18.0638 13.6667 18L18 6M10.6667 13.3333L6.00005 11C5.93622 10.9708 5.88213 10.9238 5.84421 10.8647C5.80629 10.8056 5.78613 10.7369 5.78613 10.6667C5.78613 10.5965 5.80629 10.5277 5.84421 10.4686C5.88213 10.4095 5.93622 10.3626 6.00005 10.3333L18 6"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
