import { AppButton } from "../../../../shared/components/AppButton";
import styles from "./styles.module.css";
import type { FC } from "react";

interface Props {
  onOk?: () => void;
  onCancel?: () => void;
  disabledOkBtn?: boolean;
  disabledCancelBtn?: boolean;
}

export const ModalBasicFooter: FC<Props> = ({
  onOk,
  onCancel,
  disabledOkBtn,
  disabledCancelBtn,
}) => {
  return (
    <div className={styles.footer}>
      <AppButton
        buttonType={"outlined"}
        onClick={onCancel}
        disabled={disabledCancelBtn}
      >
        Отмена
      </AppButton>
      <AppButton
        buttonType={"contained"}
        onClick={onOk}
        disabled={disabledOkBtn}
      >
        Распознать
      </AppButton>
    </div>
  );
};
