/* eslint-disable react/no-children-prop */
import { useEffect, useState, type FC, type ReactNode } from "react";
import styles from "./styles.module.css";
import type { PanelProps } from "react-resizable-panels";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { ButtonCollapse } from "./ButtonCollapse";
import classNames from "classnames";

type ResizeDirection = "horizontal" | "vertical";

interface Props extends PanelProps {
  leftBlock: ReactNode;
  rightBlock: ReactNode;
  leftBlockSize?: number;
  rightBlockSize?: number;
  leftBlockMinSize?: number;
  rightBlockMinSize?: number;
  hasButton?: boolean;
  isCollapsible?: boolean;
  rightClassName?: string;
  direction?: ResizeDirection;
}

export const ResizableWrapper: FC<Props> = ({
  direction = "horizontal",
  leftBlock,
  rightBlock,
  leftBlockSize,
  rightBlockSize,
  leftBlockMinSize,
  rightBlockMinSize,
  hasButton,
  isCollapsible,
  rightClassName,
  ...props
}) => {
  const [minSize, setMinSize] = useState<number>();

  const handleCollapsed = () => {
    setMinSize(() => (minSize === 0 ? 30 : 0));
  };

  useEffect(() => {}, []);

  return (
    <PanelGroup direction={direction} className={styles.wrapper}>
      <Panel
        collapsible={isCollapsible}
        defaultSize={leftBlockSize}
        minSize={leftBlockMinSize}
        className={classNames({
          [styles["resize-panel-left-horizontal"]]: direction === "horizontal",
        })}
        style={{ transition: "flex 0.2s ease", flex: `${minSize} 1 0` }}
        {...props}
      >
        {leftBlock}
      </Panel>
      <PanelResizeHandle children={<div className={styles["resize"]}></div>} />
      <Panel
        defaultSize={rightBlockSize}
        minSize={rightBlockMinSize}
        className={classNames(
          { [styles["resize-panel-horizontal"]]: direction === "horizontal" },
          rightClassName
        )}
        {...props}
      >
        {hasButton && (
          <ButtonCollapse
            className={classNames({ [styles.collapsed]: minSize === 0 })}
            onClick={handleCollapsed}
          />
        )}
        {rightBlock}
      </Panel>
    </PanelGroup>
  );
};
