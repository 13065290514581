import type { SelectChangeEvent, SelectProps } from "@mui/material";
import { AppLabel } from "../AppLabel";
import styles from "./styles.module.css";
import type { FC, ReactNode } from "react";
import { AppSelect } from "../AppSelect";
import { AppSelectOption } from "../AppSelect/AppSelectOption";
import type { SelectOptionType } from "./types";

type Props = SelectProps & {
  pickedValue?: string;
  label: string;
  options: SelectOptionType<unknown>[];
  children?: ReactNode | undefined;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  itemText?: string;
  isDisabled?: boolean;
  hasSettings?: boolean;
};

export const SelectBlock: FC<Props> = ({
  pickedValue,
  label,
  options,
  children,
  onChange,
  itemText,
  hasSettings,
  isDisabled,
  ...props
}) => {
  return (
    <div className={styles["block-container"]}>
      <AppLabel className={styles.label}>{label}</AppLabel>
      <div className={styles["select-container"]}>
        <AppSelect
          value={pickedValue}
          onChange={onChange}
          itemText={itemText}
          disabled={isDisabled}
          className={styles.select}
          placeholderText="Выбрать"
          {...props}
        >
          {options?.map((option) => (
            <AppSelectOption
              key={
                option?.id
                  ? (option?.id as string)
                  : (option.model_name as string)
              }
              value={
                option?.value
                  ? (option.value as string)
                  : (option.model_name as string)
              }
              className={styles.option}
            >
              {option?.value
                ? (option.value as string)
                : (option.friendly_name as string)}
            </AppSelectOption>
          ))}
        </AppSelect>
        {hasSettings && <div className={styles.settings}>{children}</div>}
      </div>
    </div>
  );
};
