import type { TableProps } from "@mui/material";
import { Table } from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";

type Props = TableProps & {
  className?: string;
  children?: React.ReactNode;
};

export const AppTable: React.FC<Props> = ({ className, children }) => {
  return (
    <Table className={classNames(styles.table, className)}>{children}</Table>
  );
};
