import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { AssistantsState } from "./types";
import {
  createNewAssistantAction,
  createThreadAction,
  deleteAssistantAction,
  getAssistantFunctionsAction,
  getAssistantsAction,
  getCompModelsAction,
  getMessagesAction,
  getStoresAction,
  postCompMessageAction,
  saveUpdatedAssistantAction,
  updateMessagesAction,
} from "./actions";
import { sortAssistantData } from "../../../shared/utils/sortAssistants";
import type { AssistantInfo } from "../../../services/api/methodsTypes";

export function getAssistantsReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(getAssistantsAction.pending, (state) => {
    state.isAssistantsLoading = true;
  });
  builder.addCase(getAssistantsAction.fulfilled, (state, action) => {
    state.assistants = sortAssistantData(action.payload.data);
    state.isAssistantsLoading = false;
  });
  builder.addCase(getAssistantsAction.rejected, (state) => {
    state.isAssistantsLoading = false;
  });
}

export function getModelsReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(getCompModelsAction.pending, (state) => {
    state.isModelsLoading = true;
  });
  builder.addCase(getCompModelsAction.fulfilled, (state, action) => {
    state.models = action.payload.models;
    state.isModelsLoading = false;
  });
  builder.addCase(getCompModelsAction.rejected, (state) => {
    state.isModelsLoading = false;
  });
}

export function getStoresReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(getStoresAction.pending, (state) => {
    state.isStoresLoading = true;
  });
  builder.addCase(getStoresAction.fulfilled, (state, action) => {
    state.stores = action.payload.data.filter(
      (item) => item.name && item.status !== "expired"
    );
    state.isStoresLoading = false;
  });
  builder.addCase(getStoresAction.rejected, (state) => {
    state.isStoresLoading = false;
  });
}

export function createThreadReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(createThreadAction.pending, (state) => {
    state.isThreadLoading = true;
  });
  builder.addCase(createThreadAction.fulfilled, (state, action) => {
    state.thread = action.payload;
    state.isThreadLoading = false;
  });
  builder.addCase(createThreadAction.rejected, (state) => {
    state.isThreadLoading = false;
  });
}

export function getAssistantFunctionsReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(getAssistantFunctionsAction.pending, (state) => {
    state.isFunctionsLoading = true;
  });
  builder.addCase(getAssistantFunctionsAction.fulfilled, (state, action) => {
    state.assistantFunctions = action.payload;
    state.isFunctionsLoading = false;
  });
  builder.addCase(getAssistantFunctionsAction.rejected, (state) => {
    state.isFunctionsLoading = false;
  });
}

export function createNewAssistantReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(createNewAssistantAction.pending, (state) => {
    state.isAssistantsLoading = true;
  });
  builder.addCase(createNewAssistantAction.fulfilled, (state, action) => {
    state.assistants = sortAssistantData([action.payload, ...state.assistants]);
    state.selectedAssistant = action.payload;
    state.isAssistantsLoading = false;
  });
  builder.addCase(createNewAssistantAction.rejected, (state) => {
    state.isAssistantsLoading = false;
  });
}

export function deleteAssistantReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(deleteAssistantAction.pending, (state) => {
    state.isAssistantsLoading = true;
  });
  builder.addCase(deleteAssistantAction.fulfilled, (state) => {
    state.isAssistantsLoading = false;
  });
  builder.addCase(deleteAssistantAction.rejected, (state) => {
    state.isAssistantsLoading = false;
  });
}

export function saveUpdatedAssistantReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(saveUpdatedAssistantAction.fulfilled, (state, action) => {
    state.selectedAssistant = {
      ...state.selectedAssistant!,
      response_format: action.payload.response_format,
    };
    state.assistants = state.assistants.map((assistant: AssistantInfo) => {
      if (assistant.id === action.payload.id) {
        return {
          ...assistant,
          response_format: action.payload.response_format,
        };
      } else {
        return assistant;
      }
    });
  });
}

export function postCompMessageReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(postCompMessageAction.pending, (state) => {
    state.isMessageLoading = true;
  });
  builder.addCase(postCompMessageAction.fulfilled, (state, action) => {
    state.messages = state.messages.map((item, index) => {
      if (state.messages.length - 1 === index) {
        return action.payload;
      } else {
        return item;
      }
    });
    state.isMessageLoading = false;
  });
  builder.addCase(postCompMessageAction.rejected, (state) => {
    state.isMessageLoading = false;
  });
}

export function getMessagesReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(getMessagesAction.pending, (state) => {
    state.isMessageLoading = true;
    state.isSendDisabled = true;
  });
  builder.addCase(getMessagesAction.fulfilled, (state, action) => {
    state.messages = action.payload.data;
    state.isMessageLoading = false;
    state.isSendDisabled = false;
  });
  builder.addCase(getMessagesAction.rejected, (state) => {
    state.isMessageLoading = false;
    state.isSendDisabled = false;
  });
}

export function updateMessagesReducerBuilder(
  builder: ActionReducerMapBuilder<AssistantsState>
) {
  builder.addCase(updateMessagesAction.pending, (state) => {
    state.isMessageLoading = true;
  });
  builder.addCase(updateMessagesAction.fulfilled, (state, action) => {
    state.messages = action.payload.data;
    state.isMessageLoading = false;
  });
  builder.addCase(updateMessagesAction.rejected, (state) => {
    state.isMessageLoading = false;
  });
}
