import type { ToggleButtonGroupProps } from "@mui/material";
import { AppToggleButtonGroup } from "../../AppToggleButtonGroup";
import { AppToggleButton } from "../../AppToggleButtonGroup/AppToggleButton";
import type { FC } from "react";
import type { ToggleType } from "../types";
import styles from "./styles.module.css";

interface Props extends ToggleButtonGroupProps {
  pickedValue?: string;
  switches?: ToggleType[];
  onChange?: () => void;
}

export const SettingsSwitch: FC<Props> = ({
  switches,
  onChange,
  pickedValue,
  ...props
}) => {
  return (
    <AppToggleButtonGroup
      value={pickedValue}
      exclusive
      onChange={onChange}
      {...props}
    >
      {switches?.map((item) => (
        <AppToggleButton
          key={item.id}
          value={item.switchValue}
          className={styles.switch}
        >
          {item.switchName}
        </AppToggleButton>
      ))}
    </AppToggleButtonGroup>
  );
};
