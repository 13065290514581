import type { FC } from "react";
import { AppButton } from "../../../../shared/components/AppButton";
import styles from "../styles.module.css";
import type { ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  btnText: string;
  onSubmit: (e: any) => void;
}

export const SubmitButton: FC<Props> = ({ onSubmit, btnText, ...props }) => {
  return (
    <div className={styles["button-container"]}>
      <AppButton
        onClick={onSubmit}
        className={styles["login-button"]}
        buttonType="contained"
        {...props}
      >
        {btnText}
      </AppButton>
      {/* <AppButton className={styles["register-button"]} buttonType="text">
            Зарегистрироваться
          </AppButton> */}
    </div>
  );
};
