import type {UserInfo} from "../../services/api/methodsTypes";
import type {NavigateFunction} from "react-router-dom";
import {routesArray} from "../../app/routing";

export const navigateToRoute = (userGroups: UserInfo["user-groups"], navigate: NavigateFunction) => {
  const firstGroup = userGroups[0];
  const activePath = window.location.pathname;

  if (activePath && userGroups.find(item => item === activePath || activePath.startsWith(item))) {
    navigate(activePath);
  } else {
    const firstUrl =
      routesArray.find((route) => route.path === firstGroup)?.path || "/";
    navigate(firstUrl);
  }
};