import {
  postAudioTranscription,
  getAudioTranscription,
} from "../../../../services/api/methods";
import { AUDIO_TRANSCRIPTION_STORAGE_INFO } from "../constants";
import type {
  AudioTranscriptionInfo,
  ChangeTranscriptionMode,
  TranscriptionModeType,
} from "../types";

export const getNumber = (value: string) =>
  Number(value.slice(0, value.length - 1));

export const getProcessingTime = (size: number, time: string) => {
  const estimate_duration = Number(time);
  const mb = size / Math.pow(1024, 2);
  const processingTime = Math.trunc(mb * 30);

  if (estimate_duration > processingTime) {
    const formattedTime = new Date(estimate_duration * 1000);
    const minutes = formattedTime.getMinutes();
    const seconds = formattedTime.getSeconds();
    return `${Math.trunc(minutes)} мин. ${seconds} сек.`;
  } else {
    const formattedTime = new Date(processingTime * 1000);
    const minutes = formattedTime.getMinutes();
    const seconds = formattedTime.getSeconds();
    return `${Math.trunc(minutes)} мин. ${seconds} сек.`;
  }
};

export const getProcessingResult = (
  action: ChangeTranscriptionMode,
  getContent: (value: string) => void,
  changeProgress: (value: number) => void,
  signal: AbortSignal
) => {
  const audionTranscriptionInfo: AudioTranscriptionInfo = JSON.parse(
    sessionStorage.getItem(AUDIO_TRANSCRIPTION_STORAGE_INFO) as string
  );

  let interval = setTimeout(function tick() {
    if (signal.aborted) {
      clearTimeout(interval);
      return;
    }
    getAudioTranscription(audionTranscriptionInfo.hash, signal)
      .then((res) => {
        switch (res.status) {
          case "processing": {
            changeProgress(getNumber(res.progress));
            action("processing" as TranscriptionModeType);
            audionTranscriptionInfo.progress = getNumber(res.progress);
            audionTranscriptionInfo.status = res.status;
            sessionStorage.setItem(
              AUDIO_TRANSCRIPTION_STORAGE_INFO,
              JSON.stringify(audionTranscriptionInfo)
            );
            return;
          }
          case "completed": {
            audionTranscriptionInfo.progress = getNumber(res.progress);
            audionTranscriptionInfo.text = res.text;
            audionTranscriptionInfo.status = "success";
            changeProgress(getNumber(res.progress));
            sessionStorage.setItem(
              AUDIO_TRANSCRIPTION_STORAGE_INFO,
              JSON.stringify(audionTranscriptionInfo)
            );
            const timer = setTimeout(() => {
              action("success" as TranscriptionModeType);
              getContent(res.text);
              clearTimeout(timer);
              clearTimeout(interval);
            }, 1000);
            return;
          }
          case "failed": {
            action("failed" as TranscriptionModeType);
            audionTranscriptionInfo.status = res.status;
            sessionStorage.setItem(
              AUDIO_TRANSCRIPTION_STORAGE_INFO,
              JSON.stringify(audionTranscriptionInfo)
            );
            clearTimeout(interval);
            return;
          }
          default:
            return;
        }
      })
      .catch(() => {
        action("failed" as TranscriptionModeType);
        clearTimeout(interval);
      });
    interval = setTimeout(tick, 3000);
  }, 3000);
};

export const uploadAudiofile = (
  file: File,
  action: ChangeTranscriptionMode,
  getContent: (value: string) => void
) => {
  let errorOccurred = false;
  return postAudioTranscription(file)
    .then((res) => {
      const processingTime = getProcessingTime(
        file.size,
        res.estimate_duration
      );
      const loadingInfo =
        "Файл загружен. Ориентировочное время обработки файла составляет:";
      sessionStorage.setItem(
        AUDIO_TRANSCRIPTION_STORAGE_INFO,
        JSON.stringify({
          hash: res.hash,
          progress: "",
          status: "ready",
          text: `${loadingInfo} ${processingTime}`,
        })
      );

      getContent(`${loadingInfo} ${processingTime}`);
    })
    .catch(() => {
      errorOccurred = true;
      const errorMessage =
        // eslint-disable-next-line quotes
        'В процессе загрузки файла произошла ошибка. Нажмите кнопку "Отмена" и попробуйте загрузить файл ещё раз';
      const audioTranscriptionInfo: AudioTranscriptionInfo = JSON.parse(
        sessionStorage.getItem(AUDIO_TRANSCRIPTION_STORAGE_INFO) as string
      );
      if (audioTranscriptionInfo) {
        audioTranscriptionInfo.status = "error";
        audioTranscriptionInfo.text = errorMessage;
      }
      sessionStorage.setItem(
        AUDIO_TRANSCRIPTION_STORAGE_INFO,
        JSON.stringify(audioTranscriptionInfo)
      );
      action("error" as TranscriptionModeType);
      getContent(errorMessage);
    })
    .finally(() => {
      if (errorOccurred) {
        action("error" as TranscriptionModeType);
      } else {
        action("ready" as TranscriptionModeType);
      }
    });
};
