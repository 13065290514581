import type { FC} from "react";
import { useState } from "react";
import { useProfile } from "../../../services/profile";
import styles from "./styles.module.css";
import { LoginInput } from "./LoginInput";
import { PasswordInput } from "./PasswordInput";
import { SubmitButton } from "./SubmitButton";
import { useNavigate } from "react-router-dom";
import { loginNewUser, loginUser, validation } from "./helpers";
import {startWrongData} from "./constants";


interface Props {
  btnText: string;
  formLocation?: "app" | "login";
}

export const AuthForm: FC<Props> = ({ btnText, formLocation }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [wrongData, setWrongData] = useState(startWrongData);
  const { setUserInfo } = useProfile();
  const navigate = useNavigate();

  const handlePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setWrongData(startWrongData);
    if (formLocation === "app") {
      loginNewUser(username, password).catch(() => {
        validation(startWrongData, username, password, setWrongData);
      });
    } else {
      loginUser(username, password, setUserInfo, navigate).catch(() => {
        validation(startWrongData, username, password, setWrongData);
      });
    }
  };

  const handleErrorRendering = () => {
    if (wrongData.username) {
      return <p className={styles.error}>Введите логин</p>;
    }
    if (wrongData.password) {
      return <p className={styles.error}>Введите пароль</p>;
    }
    if (wrongData.credentials) {
      return <p className={styles.error}>Неверный логин или пароль</p>;
    }
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Инструменты для работы с ИИ</h1>
      <LoginInput
        username={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <PasswordInput
        password={password}
        onChange={(e) => setPassword(e.target.value)}
        isPasswordVisible={isPasswordVisible}
        onClick={handlePasswordVisibility}
      />
      <SubmitButton onSubmit={onSubmit} btnText={btnText} />
      {handleErrorRendering()}
      {/* <div className={styles["checks-container"]}>
          <AppCheckbox
            className={styles["remember-me-input"]}
            labelClassName={styles["remember-me-label"]}
          >
            Запомнить меня
          </AppCheckbox>
          <a className={styles["forgot-password"]} href="#">
            Забыли пароль?
          </a>
        </div> */}
    </div>
  );
};
