import type { PropsWithChildren} from "react";
import { createContext, useState } from "react";
import type { UserInfo } from "../api/methodsTypes";
import { noop } from "../../shared/utils/noop";

type ProfileContextType = {
  userInfo: UserInfo | null;
  setUserInfo: (userInfo: UserInfo | null) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const ProfileContext = createContext<ProfileContextType>({
  userInfo: null,
  setUserInfo: noop,
  isLoading: false,
  setIsLoading: noop,
});

export const ProfileContextProvider = (props: PropsWithChildren) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ProfileContext.Provider
      value={{
        userInfo,
        setUserInfo,
        isLoading,
        setIsLoading,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};
