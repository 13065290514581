import classNames from "classnames";
import styles from "./styles.module.css";
import { useState } from "react";
import { AppIconButton } from "../../../../../shared/components/AppIconButton";
import {PlusIcon} from "../../../../../shared/icons/PlusIcon";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dropHandler: (e: React.DragEvent<HTMLDivElement>) => void;
}

export const UploadZone: React.FC<Props> = ({ dropHandler, onChange }) => {
  const [drag, setDrag] = useState<boolean>(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
    dropHandler(e);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };
  return (
    <div
      onDrop={handleDrop}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleLeave}
      className={classNames(styles["upload-zone"], {
        [styles.dragging]: drag,
      })}
    >
      <AppIconButton
        component="label"
        buttonType={"text"}
        onClick={(e: any) => {
          e.target.value = null;
        }}
        disabled={drag}
        className={styles["upload-button"]}
      >
        <PlusIcon />
        <input
          className={styles["upload-input"]}
          type="file"
          onChange={onChange}
        />
      </AppIconButton>
      <div
        className={classNames(styles["upload-zone-text"], {
          [styles["upload-zone-text-drag"]]: drag,
        })}
      >
        Перетащите или загрузите файл
      </div>
    </div>
  );
};
