import type { InputProps } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { AppIconButton } from "../../../../shared/components/AppIconButton";
import { AppInput } from "../../../../shared/components/AppInput";
import { EyeIcon } from "../../../../shared/icons/EyeIcon";
import { EyeInvisibleIcon } from "../../../../shared/icons/EyeInvisibleIcon";
import { LockIcon } from "../../../../shared/icons/LockIcon";
import styles from "../styles.module.css";
import type { ChangeEvent, FC } from "react";

interface Props extends InputProps {
  password: string;
  onChange: (
    value: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  isPasswordVisible: boolean;
  onClick: () => void;
}

export const PasswordInput: FC<Props> = ({
  password,
  onChange,
  isPasswordVisible,
  onClick,
  ...props
}) => {
  return (
    <AppInput
      value={password}
      onChange={onChange}
      type={isPasswordVisible ? "text" : "password"}
      startAdornment={
        <InputAdornment position="start">
          <LockIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <AppIconButton
            onClick={onClick}
            className={styles["eye-icon-button"]}
          >
            {isPasswordVisible ? (
              <EyeIcon className={styles["eye-icon"]} />
            ) : (
              <EyeInvisibleIcon className={styles["eye-icon"]} />
            )}
          </AppIconButton>
        </InputAdornment>
      }
      placeholder="Пароль"
      className={styles.input}
      inputClassName={styles["inner-input"]}
    />
  );
};
