import type { ButtonProps } from "@mui/material";
import { CopyIcon } from "../../../icons/CopyIcon";
import { AppIconButton } from "../../AppIconButton";
import styles from "./styles.module.css";
import type { FC } from "react";
import classNames from "classnames";

type ButtonPosition = "absolute" | "static";

interface Props extends ButtonProps {
  onClick: (e: any) => void;
  position?: ButtonPosition;
}

export const CopyButton: FC<Props> = ({ onClick, position, ...props }) => {
  return (
    <AppIconButton
      disableRipple
      onClick={onClick}
      className={classNames(styles["copy-button"], {
        [styles.absolute]: position === "absolute",
        [styles.relative]: position === "static",
      })}
      {...props}
    >
      <CopyIcon />
    </AppIconButton>
  );
};
