import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../services/redux/types";

export const selectAssistantsState = (state: RootState) =>
  state.assistantsState;

export const selectAssistants = createSelector(
  selectAssistantsState,
  (state) => {
    return state.assistants;
  }
);

export const selectModels = createSelector(selectAssistantsState, (state) => {
  return state.models;
});

export const selectStores = createSelector(selectAssistantsState, (state) => {
  return state.stores;
});

export const selectSelectedAssistant = createSelector(
  selectAssistantsState,
  (state) => {
    return state.selectedAssistant;
  }
);

export const selectThread = createSelector(selectAssistantsState, (state) => {
  return state.thread;
});

export const selectMessages = createSelector(selectAssistantsState, (state) => {
  return state.messages;
});

export const selectAssistantFunctions = createSelector(
  selectAssistantsState,
  (state) => {
    return state.assistantFunctions;
  }
);

export const selectIsAssistantsLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isAssistantsLoading;
  }
);

export const selectIsModelsLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isModelsLoading;
  }
);

export const selectIsStoresLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isStoresLoading;
  }
);

export const selectIsSendDisabled = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isSendDisabled;
  }
);

export const selectIsMessageLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isMessageLoading;
  }
);

export const selectIsFunctionsLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isFunctionsLoading;
  }
);

export const selectIsThreadLoading = createSelector(
  selectAssistantsState,
  (state) => {
    return state.isThreadLoading;
  }
);

export const selectSwitchedDialogs = createSelector(
  selectAssistantsState,
  (state) => {
    return state.selectedAssistant?.metadata?.dialogs === "true";
  }
);
