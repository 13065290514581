import classNames from "classnames";
import styles from "./styles.module.css";
import type {
  AccordionProps} from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { AppLabel } from "../AppLabel";

type Props = AccordionProps & {
  className?: string;
  headerClassName?: string;
  title: string;
  rightElement?: React.ReactNode;
  id?: string;
  children?: React.ReactNode;
};

export const AppAcordion: React.FC<Props> = ({
  className,
  headerClassName,
  title,
  rightElement,
  id,
  children,
  ...props
}) => {
  return (
    <Accordion
      disableGutters
      className={classNames(styles.accordion, className)}
      classes={{
        expanded: styles.expanded,
      }}
      {...props}
    >
      <AccordionSummary
        className={classNames(styles.header, headerClassName)}
        classes={{
          content: styles["header-content"],
          expanded: styles["expanded-header"],
        }}
        expandIcon={<ArrowIcon />}
      >
        <div className={styles["header-content-container"]}>
          {id && <AppLabel>{id}</AppLabel>}
          <span className={styles.title}>{title}</span>
        </div>
        {rightElement}
      </AccordionSummary>
      <AccordionDetails
        onClick={(e) => e.stopPropagation()}
        className={styles.details}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
