import type { FC } from "react";
import { AppButton } from "../../../shared/components/AppButton";
import { PlusIcon } from "../../../shared/icons/PlusIcon";
import { AppMenuItem } from "../../../shared/components/AppMenu/AppMenuItem";
import type { MenuItemProps } from "@mui/material";

interface Props extends MenuItemProps {
  addUser: () => void;
}

export const AddUserButton: FC<Props> = ({ addUser, ...props }) => {
  return (
    <AppMenuItem {...props}>
      <AppButton buttonType="text" onClick={addUser} icon={<PlusIcon />}>
        Добавить пользователя
      </AppButton>
    </AppMenuItem>
  );
};
