import { AppDialog } from "../..";
import { AppButton } from "../../../AppButton";
import classNames from "classnames";
import styles from "./styles.module.css";
import { AppSelect } from "../../../AppSelect";
import type { AssistantInfoWithPlaceholder } from "../../../../../pages/ChatbotPage";
import { AppSelectOption } from "../../../AppSelect/AppSelectOption";
import { useEffect, useState } from "react";

interface Props {
  className?: string;
  dialogOpen: boolean;
  assistants: AssistantInfoWithPlaceholder[];
  selectedAssistantId: string;
  handleDialogClose: () => void;
  handleClick: (newSelectedAssistant: string) => void;
}

export const AppChangeTableAssistantDialog: React.FC<Props> = ({
  className,
  dialogOpen,
  assistants,
  selectedAssistantId,
  handleDialogClose,
  handleClick,
}) => {
  const [localSelectedAssistant, setLocalSelectedAssistant] = useState<
    string | null
  >(null);

  const getAssistantList = () => {
    if (!assistants || assistants.length === 0) return [];
    const newList = assistants.filter((assistant) => {
      return assistant.id !== "emptyId";
    });
    return newList;
  };

  const localChangeSelectedAssistant = () => {
    if (!localSelectedAssistant) return;
    handleClick(localSelectedAssistant);
    handleDialogClose();

    const timer = setTimeout(() => {
      setLocalSelectedAssistant(selectedAssistantId);
    }, 300);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    setLocalSelectedAssistant(selectedAssistantId);
  }, [selectedAssistantId]);

  return (
    <AppDialog
      classes={{ paper: classNames(styles.dialog, className) }}
      open={dialogOpen}
    >
      <h1 className={styles["dialog-title"]}>Изменение ассистента</h1>
      <AppSelect
        className={styles.select}
        value={localSelectedAssistant}
        onChange={(e: any) => setLocalSelectedAssistant(e.target.value)}
        itemText={
          assistants?.find(
            (assistant) => assistant.id === localSelectedAssistant
          )?.name
        }
      >
        {getAssistantList().map((assistant) => (
          <AppSelectOption key={assistant.id} value={assistant.id}>
            {assistant.name}
          </AppSelectOption>
        ))}
      </AppSelect>

      <div className={styles["dialog-button-container"]}>
        <AppButton small buttonType="outlined" onClick={handleDialogClose}>
          Отмена
        </AppButton>
        <AppButton
          small
          buttonType="contained"
          onClick={localChangeSelectedAssistant}
        >
          Сменить
        </AppButton>
      </div>
    </AppDialog>
  );
};
