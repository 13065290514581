import type { FC } from "react";
import { AppButton } from "../../../../../shared/components/AppButton";
import { EraserIcon } from "../../../../../shared/icons/EraserIcon";

interface Props {
  clearChat: () => void;
  className?: string;
}

export const ClearChatButton: FC<Props> = ({ clearChat, className }) => {
  return (
    <AppButton
      buttonType={"text"}
      icon={<EraserIcon />}
      className={className}
      onClick={clearChat}
    >
      Очистить чат
    </AppButton>
  );
};
