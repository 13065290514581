import type { AppIconProps } from "../IconTypes";

export const SendIcon: React.FC<AppIconProps> = ({
  width = 24,
  height = 24,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M5.25977 12H21.3337M5.25977 12L2.70257 19.2736C2.66467 19.3703 2.65686 19.4753 2.68006 19.5761C2.70326 19.6769 2.7565 19.7692 2.83344 19.8422C2.91039 19.9151 3.00781 19.9656 3.11413 19.9876C3.22045 20.0096 3.33119 20.0022 3.4332 19.9663L21.3337 12M5.25977 12L2.70257 4.72645C2.66467 4.62973 2.65686 4.52474 2.68006 4.42393C2.70326 4.32313 2.7565 4.23077 2.83344 4.15781C2.91039 4.08486 3.00781 4.03438 3.11413 4.01239C3.22045 3.99039 3.33119 3.9978 3.4332 4.03373L21.3337 12"
        stroke="#1D9F81"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
