import type { MenuProps } from "@mui/material";
import { Menu } from "@mui/material";
import { forwardRef } from "react";

export const AppMenu = forwardRef<HTMLDivElement, MenuProps>(
  ({ children, ...props }, ref) => {
    return (
      <Menu ref={ref} {...props}>
        {children}
      </Menu>
    );
  }
);
