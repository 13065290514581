import type { ChangeEvent } from "react";
import { useCallback, useEffect, useReducer, useState } from "react";

import { ResizableWrapper } from "../../shared/components/ResizableWrapper";
import styles from "./styles.module.css";
import {
  createTextGeneration,
  getAssistants,
} from "../../services/api/methods";
import type { SelectChangeEvent } from "@mui/material";
import { INITIAL_STATE } from "./constants";
import { stylistReducer } from "./helpers";
import type { SettingType } from "../../features/Stylisation/StylistMain/types";
import { AppSidebar } from "../../common/Sidebar";
import { StylistMain } from "../../features/Stylisation/StylistMain";
import { StylistResult } from "../../features/Stylisation/StylistResult";

export const StylistPage = () => {
  const [state, dispatchStylist] = useReducer(stylistReducer, INITIAL_STATE);
  const [typing, setTyping] = useState(false);
  const [settings, setSettings] = useState<SettingType>("вручную");
  const [isLoading, setIsLoading] = useState(false);
  const {
    assistantList,
    selectedAssistant,
    originalText,
    result,
    postObject,
    dateUpdate,
  } = state;

  useEffect(() => {
    getAssistants().then((res) => {
      dispatchStylist({
        type: "SET_ASSISTANT_LIST",
        payload: res.data,
      });
    });
  }, []);

  useEffect(() => {
    if (typing && settings === "auto") {
      const timer = setTimeout(() => {
        setIsLoading(true);
        setTyping(false);
        createTextGeneration(postObject)
          .then((res) => {
            const text = res.choices.map(
              (choice: any) => choice?.message.content
            );
            dispatchStylist({
              type: "SET_RESULT",
              payload: text,
            });
          })
          .then(() => setIsLoading(false));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [state, typing, originalText]);

  const handleSwitchSettings = () => {
    if (settings === "auto") {
      setSettings("вручную");
    } else {
      setSettings("auto");
    }
  };

  const handleCleanResult = () => {
    dispatchStylist({
      type: "SET_RESULT",
      payload: "",
    });
  };

  const handleSelectAssistant = (e: SelectChangeEvent<unknown>) => {
    const copyAssistants = [...assistantList];
    const chosenAssistant = copyAssistants.find(
      (assistant) => assistant.name === e.target.value
    );
    dispatchStylist({
      type: "SET_SELECTED_ASSISTANT",
      payload: chosenAssistant,
    });
  };

  const handleChangeOriginalText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatchStylist({
        type: "SET_ORIGINAL_TEXT",
        payload: e.target.value,
      });
      setTyping(true);
    },
    []
  );

  const handleChangeResult = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchStylist({
      type: "SET_RESULT",
      payload: e.target.value,
    });
    dispatchStylist({
      type: "SET_DATE_UPDATE",
      payload: new Date()
        .toLocaleDateString("ru-RU", {
          hour: "numeric",
          minute: "numeric",
        })
        .replace(",", ""),
    });
  };

  const handleClickStylist = () => {
    setIsLoading(true);
    createTextGeneration(postObject)
      .then((res) => {
        const text = res.choices.map((choice: any) => choice?.message.content);
        dispatchStylist({
          type: "SET_RESULT",
          payload: text,
        });
      })
      .then(() => setIsLoading(false));
  };

  return (
    <div className={styles["page-layout"]}>
      <AppSidebar
        menuItems={[
          {
            id: "1",
            name: "Default",
          },
        ]}
        selectedId={"1"}
        onSelect={() => {}}
        hasHeader={false}
      />
      <ResizableWrapper
        leftBlock={
          <StylistMain
            selectedAssistantName={
              selectedAssistant === null
                ? ""
                : (selectedAssistant?.name as string)
            }
            text={originalText}
            onSelect={handleSelectAssistant}
            onChange={handleChangeOriginalText}
            onClick={handleClickStylist}
            assistants={assistantList}
            switchValue={settings}
            handleSwitch={handleSwitchSettings}
            isButtonDisabled={isLoading || settings === "auto"}
            className={styles.wrapper}
          />
        }
        rightBlock={
          <StylistResult
            results={result}
            date={dateUpdate}
            onChange={handleChangeResult}
            onClean={handleCleanResult}
            isResultsDisabled={isLoading}
            className={styles.wrapper}
            isLoading={isLoading}
          />
        }
        isCollapsible={false}
        leftBlockSize={50}
        leftBlockMinSize={35}
        rightBlockSize={50}
        rightBlockMinSize={35}
        rightClassName={styles["right-block"]}
      />
    </div>
  );
};
