import type { ButtonProps } from "@mui/material";
import type { FC } from "react";
import styles from "../styles.module.css";
import {AppIconButton} from "../../../../../../shared/components/AppIconButton";
import {SendMessageIcon} from "../../../../../../shared/icons/SendMessageIcon";

interface Props extends ButtonProps {
  sendMessage?: (e: any) => void;
}

export const SendMessageButton: FC<Props> = ({ sendMessage, ...props }) => {
  return (
    <AppIconButton
      onClick={sendMessage}
      className={styles["chat-button"]}
      {...props}
    >
      <SendMessageIcon className={styles.icon} />
    </AppIconButton>
  );
};
