import type { FC } from "react";
import { AppLinearLoading } from "../../../../../shared/components/AppLoading/AppLenearLoading";
import styles from "./styles.module.css";
import type { LinearProgressProps } from "@mui/material";
export const FileUploadIndicator: FC<LinearProgressProps> = ({ ...props }) => {
  return (
    <div className={styles.container}>
      <p>Файл загружается...</p>
      <AppLinearLoading
        variant="determinate"
        className={styles["linear-loading"]}
        {...props}
      />
    </div>
  );
};
