import { APP_MULTI_USER_API } from "../../../services/api/constants";
import type { AuthUserType } from "../../../services/api/methodsTypes";

export const multiUserApi = {
  users:
    localStorage.getItem(APP_MULTI_USER_API) !== null
      ? localStorage.getItem(APP_MULTI_USER_API)
      : localStorage.setItem(APP_MULTI_USER_API, JSON.stringify([])),

  parseToArray: function (): AuthUserType[] {
    return JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string);
  },

  getActiveUser: function (): AuthUserType | undefined {
    const user = [
      ...JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string),
    ].find((item) => item.isActive === true);
    return user;
  },

  getUserKey: function (key: keyof AuthUserType): string {
    const user = this.getActiveUser();
    return user?.[key] as string;
  },

  checkUserValue: function (key: keyof AuthUserType, value: string | boolean) {
    const users = [
      ...JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string),
    ];
    let check = false;
    users.forEach((item) => {
      if (item[key] === value) {
        check = true;
      }
    });
    return check;
  },

  toggleUser: function (value: string) {
    const users = [
      ...JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string),
    ];

    users.forEach((item) => {
      if (item.user_name === value) {
        item.isActive = true;
      }
      if (item.user_name !== value) {
        item.isActive = false;
      }
    });

    localStorage.setItem(APP_MULTI_USER_API, JSON.stringify(users));
  },

  addUser: function (user: AuthUserType) {
    const userJson = localStorage.getItem(APP_MULTI_USER_API);
    let users = userJson ? JSON.parse(userJson) : [];
    const existedUser = users.find(
      (item: AuthUserType) => item.user_name === user.user_name
    );
    if (existedUser) {
      users = users.map((item: AuthUserType) => {
        if (item.user_name === existedUser.user_name) {
          return user;
        } else {
          return {
            ...item,
            isActive: false,
          };
        }
      });
    } else {
      users.forEach((item: AuthUserType) => {
        if (item.isActive === true) {
          item.isActive = false;
        }
      });
      users.push(user);
    }

    localStorage.setItem(APP_MULTI_USER_API, JSON.stringify(users));
  },

  setUserKey: function (
    users: AuthUserType[],
    key: keyof AuthUserType,
    value: string | boolean
  ) {
    const multiUser = users.map((item) => {
      if (item.isActive === true) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    return JSON.stringify(multiUser);
  },

  removeUser: function () {
    const multiUser = [
      ...JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string),
    ];
    if (multiUser.length === 0) {
      return;
    }
    if (multiUser.length > 1) {
      const users = multiUser.filter((item) => item.isActive === false);
      users[0].isActive = true;
      console.log(users);
      localStorage.setItem(APP_MULTI_USER_API, JSON.stringify(users));
    } else {
      localStorage.setItem(APP_MULTI_USER_API, JSON.stringify([]));
    }
  },

  removeUserKey: function (keys: string[]) {
    const users = [
      ...JSON.parse(localStorage.getItem(APP_MULTI_USER_API) as string),
    ];
    users.forEach((item) => {
      if (item.isActive === true) {
        const newItem = JSON.stringify(item, keys);
        item = { ...JSON.parse(newItem) };
        return item;
      }
    });
    localStorage.setItem(APP_MULTI_USER_API, JSON.stringify(users));
  },
};
