import type { ButtonProps } from "@mui/material";
import type { FC } from "react";
import styles from "../styles.module.css";
import {AppIconButton} from "../../../../../../shared/components/AppIconButton";
import {PaperclipIcon} from "../../../../../../shared/icons/PaperclipIcon";

interface Props extends ButtonProps {
  attachFile?: (e: any) => void;
  onChange?: (e: any) => void;
}

export const AttachFileButton: FC<Props> = ({
  attachFile,
  onChange,
  ...props
}) => {
  return (
    <AppIconButton
      component="label"
      onClick={attachFile}
      className={styles["chat-button"]}
      {...props}
    >
      <input
        className={styles["upload-input"]}
        type="file"
        onChange={onChange}
      />
      <PaperclipIcon className={styles.icon} />
    </AppIconButton>
  );
};
