import type { FC, ReactNode } from "react";
import styles from "./styles.module.css";
import { AttachFileButton } from "./MessageSendingButtons/AttachFileButton";
import { SendMessageButton } from "./MessageSendingButtons/SendMessageButton";
import classNames from "classnames";
import {AppInputBase} from "../../../../shared/components/AppInputBase";
import {AppButton} from "../../../../shared/components/AppButton";
import {EraserIcon} from "../../../../shared/icons/EraserIcon";

interface Props {
  sendValue: string;
  onChange: (e: any) => void;
  additionalContent?: ReactNode;
  hasAdditionalContent?: boolean;
  hasFileAttachment?: boolean;
  hasFooter?: boolean;
  sendMessage?: () => void;
  clearChat?: () => void;
  className?: string;
  handleKeyDown?: (event: any) => void;
}

export const ChatMessageSending: FC<Props> = ({
  sendValue,
  onChange,
  additionalContent,
  hasAdditionalContent,
  hasFileAttachment,
  hasFooter,
  sendMessage,
  clearChat,
  handleKeyDown,
  className,
}) => {
  return (
    <div className={classNames(styles["message-sending-wrapper"], className)}>
      <div className={styles["message-sending"]}>
        {hasFileAttachment && <AttachFileButton />}
        <div className={styles["center-block"]}>
          {hasAdditionalContent && additionalContent}
          <AppInputBase
            value={sendValue}
            onChange={onChange}
            handleSendKeyDown={handleKeyDown}
            rows={4}
            maxRows={4}
            className={styles["chat-input"]}
            placeholder={"Введите сообщение"}
          />
          <SendMessageButton
            onClick={sendMessage}
            className={styles["send-button"]}
          />
        </div>
      </div>
      {hasFooter && (
        <div className={styles["message-sending-footer"]}>
          <AppButton
            buttonType={"text"}
            icon={<EraserIcon />}
            className={styles["clear-button"]}
            onClick={clearChat}
          >
            Очистить чат
          </AppButton>
        </div>
      )}
    </div>
  );
};
