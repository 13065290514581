import { AppProvider } from "./providers";
import { AppRouting } from "./routing";

export const App = () => {
  return (
    <AppProvider>
      <AppRouting />
    </AppProvider>
  );
};
