import type { MenuItemProps } from "@mui/material";
import type { FC } from "react";
import { AppMenuItem } from "../../../shared/components/AppMenu/AppMenuItem";
import { LoginIcon } from "../../../shared/icons/LoginIcon";

interface Props extends MenuItemProps {
  name: string | undefined;
}

export const AccountMenuItem: FC<Props> = ({ name, ...props }) => {
  return (
    <AppMenuItem {...props}>
      <LoginIcon />
      {name}
    </AppMenuItem>
  );
};
