import { useState } from "react";
import { AppUnderlinedTitle } from "../../../shared/components/AppUnderlinedTitle";
import { AppLabel } from "../../../shared/components/AppLabel";
import { AppSelect } from "../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../shared/components/AppSelect/AppSelectOption";
import { AppDatePicker } from "../../../shared/components/AppDatePicker";
import { AppAcordion } from "../../../shared/components/AppAcordion";
import { AppChatContainer } from "../../../shared/components/AppChatContainer";
import type { ChatbotWithChats, UserListType } from "../../../pages/ChatbotPage";
import type {
  ChatMessageObject,
  Message,
  MessageContent,
} from "../../../services/api/methodsTypes";
import { AppLoading } from "../../../shared/components/AppLoading";
import { getChatMessages } from "../../../services/api/methods";
import styles from "./styles.module.css";
import classNames from "classnames";
import { getMessagesForDates } from "../../../shared/utils/chatbotFunctions";

interface Props {
  selectedBot: ChatbotWithChats;
  selectedUserList: UserListType | null;
  handleChangeChatbotList: (selectedBot: ChatbotWithChats) => void;
  isChatsLoading: boolean;
}

export const ChatbotDialogs: React.FC<Props> = ({
  selectedBot,
  selectedUserList,
  handleChangeChatbotList,
  isChatsLoading,
}) => {
  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(
    null
  );
  const [dateWarning, setDateWarning] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);

  const handleChannelChange = (event: any) => {
    const oldItem = selectedBot;
    oldItem.selectedChat = event.target.value;
    handleChangeChatbotList(oldItem);
  };

  const handleDateChange = (
    date: Date | null,
    type: "dateStart" | "dateEnd"
  ) => {
    const oldItem = selectedBot;
    if (!oldItem.dateStart || !oldItem.dateEnd) return;
    oldItem[type] = date;
    handleChangeChatbotList(oldItem);

    // Проверка на корректный диапазон дат
    if (
      (type === "dateStart" &&
        date &&
        selectedBot.dateEnd &&
        date > selectedBot.dateEnd) ||
      (type === "dateEnd" &&
        date &&
        selectedBot.dateStart &&
        date < selectedBot.dateStart)
    ) {
      setDateWarning(true);
    } else {
      setDateWarning(false);
    }
  };

  const handleAccordionClick = (id: string) => {
    if (selectedAccordion === id) {
      setSelectedAccordion(null);
      return;
    }
    setSelectedAccordion(id);
  };

  /*   const handleBanClick = (e: any, id: string) => {
    e.stopPropagation();
    const newChat = selectedBot.chats?.map((chat) => {
      if (chat.chat_id === id) {
        chat.isBlocked = !chat.isBlocked;
      }
      return chat;
    });
    const oldItem = selectedBot;
    oldItem.chats = newChat;
    handleChangeChatbotList(oldItem);
  }; */

  const renderChatSelectOptions = () => {
    if (selectedBot.chats) {
      const newChats = [...selectedBot.chats];
      newChats.unshift({
        chat_id: "emptyId",
        chat_name: "Все чаты",
        bot_name: "",
        asst_name: "",
      });

      return newChats.map((chat) => (
        <AppSelectOption key={chat.chat_id} value={chat.chat_id}>
          {chat.chat_name}
        </AppSelectOption>
      ));
    }
  };

  const getDisplayedMessages = (chat_id: string) => {
    const selectedChatMessages = selectedBot.chats?.find(
      (chat) => chat.chat_id === chat_id
    )?.messages;

    if (!selectedChatMessages || !selectedChatMessages?.length) {
      setMessagesLoading(true);
      getChatMessages(selectedBot.ref, chat_id)
        .then((res) => {
          const fetchedMessages = res.messages;
          const newBot = { ...selectedBot };
          const selectedChat = newBot.chats?.find(
            (chat) => chat.chat_id === chat_id
          );
          if (selectedChat) selectedChat.messages = fetchedMessages;
          handleChangeChatbotList(newBot);
        })
        .finally(() => setMessagesLoading(false));
    }
  };

  const formatToDisplayedMessages = (
    messages: ChatMessageObject[]
  ): Message[] => {
    const messagesByDate = getMessagesForDates(
      messages,
      selectedBot.dateStart,
      selectedBot.dateEnd
    );
    return messagesByDate
      .map((message) => ({
        ...message,
        role: message.role,
        content: [
          {
            type: "text",
            text: {
              value: message.message_text,
              annotations: [],
            },
          },
        ] as unknown as MessageContent[],
        id: "",
        object: "",
        assistant_id: "",
        run_id: "",
        created_at: "",
        thread_id: "",
      }))
      .reverse();
  };

  const checkIsBlocked = (chat_id: string): boolean => {
    if (selectedBot.black_list?.includes(chat_id)) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.container}>
      <AppUnderlinedTitle className={styles.title} leftContent="Диалоги" />
      {isChatsLoading ? (
        <AppLoading small />
      ) : (
        <>
          <AppLabel className={styles.label}>Каналы</AppLabel>
          <AppSelect
            className={styles.select}
            value={selectedBot.selectedChat}
            onChange={handleChannelChange}
            itemText={
              selectedBot.chats?.find(
                (chat) => chat.chat_id === selectedBot.selectedChat
              )?.chat_name || "Все чаты"
            }
          >
            {renderChatSelectOptions()}
          </AppSelect>
          <div className={styles["date-container"]}>
            <span className={styles["date-label"]}>Период с</span>
            <AppDatePicker
              value={selectedBot.dateStart}
              onChange={(date) => handleDateChange(date, "dateStart")}
            />
            <span className={styles["date-label"]}>по</span>
            <AppDatePicker
              value={selectedBot.dateEnd}
              onChange={(date) => handleDateChange(date, "dateEnd")}
            />
          </div>
          {dateWarning && (
            <p className={styles["date-warning"]}>Неверный диапазон</p>
          )}
          {selectedBot.chats?.length === 0 ? (
            <AppLabel className={styles["no-chats-label"]}>Нет чатов</AppLabel>
          ) : (
            <div className={styles["accordion-container"]}>
              {selectedBot.chats?.map((chat) => {
                if (
                  selectedBot.selectedChat &&
                  (selectedBot.selectedChat === "emptyId" ||
                    selectedBot.selectedChat === chat.chat_id)
                ) {
                  return (
                    <AppAcordion
                      key={chat.chat_id}
                      className={styles.accordion}
                      headerClassName={classNames({
                        [styles["accordion-blocked"]]: checkIsBlocked(
                          chat.chat_id
                        ),
                      })}
                      title={chat.chat_name}
                      expanded={selectedAccordion === chat.chat_id}
                      onClick={() => {
                        handleAccordionClick(chat.chat_id);
                        getDisplayedMessages(chat.chat_id);
                      }}
                      // rightElement={
                      //   <AppButton
                      //     small
                      //     className={classNames(styles["ban-button"], {
                      //       [styles["ban-button-blocked"]]: checkIsBlocked(
                      //         chat.chat_id
                      //       ),
                      //     })}
                      //     buttonType="text"
                      //     icon={
                      //       checkIsBlocked(chat.chat_id) ? (
                      //         <BanIcon />
                      //       ) : (
                      //         <UnbanIcon />
                      //       )
                      //     }
                      //     onClick={(e) => handleBanClick(e, chat.chat_id)}
                      //   >
                      //     {checkIsBlocked(chat.chat_id)
                      //       ? "Разблокировать"
                      //       : "Блокировать"}
                      //   </AppButton>
                      // }
                    >
                      {messagesLoading ? (
                        <AppLoading small />
                      ) : (
                        <AppChatContainer
                          chatClassName={styles.chat}
                          messages={formatToDisplayedMessages(
                            chat.messages || []
                          )}
                        />
                      )}
                    </AppAcordion>
                  );
                }
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
