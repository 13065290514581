import type { ChangeEvent } from "react";
import { AppIconButton } from "../../../../shared/components/AppIconButton";
import { AppInputBase } from "../../../../shared/components/AppInputBase";
import { TrashIcon } from "../../../../shared/icons/TrashIcon";
import type { inputValue } from "..";
import styles from "./styles.module.css";

interface Props {
  onDelete: (id: string) => void;
  onInputChange: (id: string, value: string) => void;
  inputValueObj: inputValue;
}

export const SidebarListItem: React.FC<Props> = ({
  onDelete,
  onInputChange,
  inputValueObj,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onInputChange(inputValueObj.id, event.target.value);
  };

  return (
    <li className={styles["item-container"]}>
      <AppInputBase
        small
        rows={undefined}
        placeholder="Введите значение"
        fullWidth
        value={inputValueObj.value}
        onChange={handleChange}
      />
      <AppIconButton onClick={() => onDelete(inputValueObj.id)}>
        <TrashIcon />
      </AppIconButton>
    </li>
  );
};
