import type { AppIconProps } from "../IconTypes";

export const SparksIcon: React.FC<AppIconProps> = ({
  width = 16,
  height = 16,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M10.6667 12.0001C11.0203 12.0001 11.3594 12.1406 11.6095 12.3906C11.8595 12.6407 12 12.9798 12 13.3334C12 12.9798 12.1405 12.6407 12.3905 12.3906C12.6406 12.1406 12.9797 12.0001 13.3333 12.0001C12.9797 12.0001 12.6406 11.8596 12.3905 11.6096C12.1405 11.3595 12 11.0204 12 10.6667C12 11.0204 11.8595 11.3595 11.6095 11.6096C11.3594 11.8596 11.0203 12.0001 10.6667 12.0001ZM10.6667 4.00008C11.0203 4.00008 11.3594 4.14056 11.6095 4.39061C11.8595 4.64065 12 4.97979 12 5.33341C12 4.97979 12.1405 4.64065 12.3905 4.39061C12.6406 4.14056 12.9797 4.00008 13.3333 4.00008C12.9797 4.00008 12.6406 3.85961 12.3905 3.60956C12.1405 3.35951 12 3.02037 12 2.66675C12 3.02037 11.8595 3.35951 11.6095 3.60956C11.3594 3.85961 11.0203 4.00008 10.6667 4.00008ZM6 12.0001C6 10.9392 6.42143 9.9218 7.17157 9.17165C7.92172 8.42151 8.93913 8.00008 10 8.00008C8.93913 8.00008 7.92172 7.57865 7.17157 6.82851C6.42143 6.07836 6 5.06095 6 4.00008C6 5.06095 5.57857 6.07836 4.82843 6.82851C4.07828 7.57865 3.06087 8.00008 2 8.00008C3.06087 8.00008 4.07828 8.42151 4.82843 9.17165C5.57857 9.9218 6 10.9392 6 12.0001Z"
        stroke="#1D9F81"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
