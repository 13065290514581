import { AppNavLink } from "../../shared/components/AppNavLink";
import { AppIconButton } from "../../shared/components/AppIconButton";
import { LoginIcon } from "../../shared/icons/LoginIcon";
import logo from "../../shared/assets/img/logo.png";
import styles from "./styles.module.css";
import { logout } from "../../services/api/methods";
import { useProfile } from "../../services/profile";
import { routesArray } from "../../app/routing";
import { LogoutIcon } from "../../shared/icons/LogoutIcon";
import { default as version } from "../../../package.json";
import { SupportButton } from "./SupportButton";
import { useAppDispatch } from "../../services/redux/hooks/use-dispatch";
import { clearAssistantsStateAction } from "../../features/Assistants/redux/slice";
import { multiUserApi } from "../../shared/utils/multiUserFunctions";
import { AccountMenu } from "../AccountMenu";
import { useState } from "react";
import { AppButton } from "../../shared/components/AppButton";

export const AppHeader = () => {
  const { userInfo } = useProfile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout().then(() => {
      multiUserApi.removeUser();
      window.location.reload();
      dispatch(clearAssistantsStateAction());
    });
  };
  return (
    <>
      <header className={styles.header}>
        <div className={styles["nav-container"]}>
          <img className={styles.logo} src={logo} alt="logo" />
          {routesArray.map((route) => {
            if (userInfo?.["user-groups"].includes(route.path)) {
              return (
                <AppNavLink
                  key={route.path}
                  activeClassName={styles.active}
                  className={styles["header-link"]}
                  to={route.path}
                >
                  {route.name}
                </AppNavLink>
              );
            }
          })}
        </div>
        <div className={styles["login-container"]}>
          <SupportButton />
          <p className={styles.version}>{version.version}-dev</p>
          <AppButton
            className={styles["login-button"]}
            onClick={handleMenu}
            aria-controls={open ? "account-menu" : undefined}
            buttonType="text"
            icon={<LoginIcon />}
          >
            <p className={styles.name}>
              {multiUserApi.getUserKey("user_name")}
            </p>
          </AppButton>
          <AppIconButton onClick={handleLogout} buttonType="outlined">
            <LogoutIcon />
          </AppIconButton>
        </div>
        <AccountMenu
          open={open}
          id="account-menu"
          userList={multiUserApi.parseToArray()}
          onClose={handleClose}
          handleCloseMenu={handleClose}
          anchorEl={anchorEl}
          logout={handleLogout}
        />
      </header>
    </>
  );
};
