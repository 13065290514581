import { useEffect, useState } from "react";
import { DialogTitle } from "@mui/material";
import { AppSelect } from "../../../shared/components/AppSelect";
import { AppSelectOption } from "../../../shared/components/AppSelect/AppSelectOption";
import { AppButton } from "../../../shared/components/AppButton";
import { AppDialog } from "../../../shared/components/AppDialog";
import type { SelectedValue } from "../../../pages/ComparisonPage";
import type { CompModel } from "../../../services/api/methodsTypes";
import { findModelName } from "../CompChat";
import styles from "./styles.module.css";

export interface CompDialogProps {
  models: CompModel[];
  open: boolean;
  selectedValues: SelectedValue[];
  chatNumber: number;
  setIsOpen: (isOpen: boolean) => void;
  onSelectValue: (tempValue: string, chatNumber: number) => void;
}

export const CompDialog: React.FC<CompDialogProps> = ({
  models,
  open,
  selectedValues,
  chatNumber,
  setIsOpen,
  onSelectValue,
}) => {
  const [tempValue, setTempValue] = useState(selectedValues[chatNumber].value);

  const handleSelectChange = (e: any) => {
    setTempValue(e.target.value);
  };

  const handleClose = () => {
    setIsOpen(false);
    const timeout = setTimeout(
      () => setTempValue(selectedValues[chatNumber].value),
      300
    );

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    setTempValue(selectedValues[chatNumber].value);
  }, [chatNumber]);

  return (
    <AppDialog
      className={styles.container}
      classes={{ paper: styles.dialog }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle className={styles.title}>Выбор модели</DialogTitle>
      <AppSelect
        className={styles.select}
        displayEmpty
        renderValue={() => {
          if (tempValue === "")
            return <span className={styles.placeholder}>Выберите модель</span>;
          else return findModelName(models, tempValue);
        }}
        value={tempValue}
        onChange={handleSelectChange}
      >
        {models.map((model) => (
          <AppSelectOption key={model.model_name} value={model.model_name}>
            {model.friendly_name}
          </AppSelectOption>
        ))}
      </AppSelect>
      <div className={styles["button-container"]}>
        <AppButton buttonType="outlined" onClick={handleClose}>
          Отмена
        </AppButton>
        <AppButton
          buttonType="contained"
          onClick={() => onSelectValue(tempValue, chatNumber)}
        >
          Выбрать
        </AppButton>
      </div>
    </AppDialog>
  );
};
