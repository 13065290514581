import type {
  AssistantFunction,
  AssistantInfo,
  AssistantPostInfo,
  AssistantToolsObject,
} from "../../../services/api/methodsTypes";


export const addSelectedFieldToFunctions = (
	functions: AssistantFunction[],
	tools: AssistantToolsObject[]
): AssistantFunction[] => {
	return functions.map((_function) => {
		const isFunctionSelected = tools.find(
			(tool) =>
				tool.type === "function" &&
				tool.function?.name === _function.function_name
		);

		return {
			..._function,
			selected: !!isFunctionSelected,
		};
	});
};

export const updateAssistantToolsAndResources = (
	assistant: AssistantInfo,
): AssistantInfo => {
	if (assistant.response_format === "auto") {
    return {
      ...assistant,
      tool_resources: {
        ...assistant.tool_resources,
        code_interpreter: {
          ...assistant.tool_resources?.code_interpreter,
          file_ids: [],
        }
      },
      tools: [
        ...assistant.tools,
        {type: "code_interpreter"}
      ]
    };
  } else {
    return {
      ...assistant,
      tools: assistant.tools.filter(item => item.type === "function"),
      tool_resources: {
        ...assistant.tool_resources,
        code_interpreter: {
          file_ids: [],
        },
        file_search: {
          vector_store_ids: [],
        }
      }
    };
  }
};

export const getFilteredAssistantToPost = (
	assistant: AssistantInfo
): AssistantPostInfo => {
	const { id: _, ...restAssistant }: AssistantInfo = assistant;
	const assistantCopy = { ...restAssistant, description: "" };

	assistantCopy.name.trim();
	assistantCopy.instructions.trim();
	if (assistantCopy.instructions === "") {
		assistantCopy.instructions = " ";
	}

	delete assistantCopy.object;
	delete assistantCopy.created_at;

	// delete assistantCopy.localAvailableFunctions;
	// delete assistantCopy.localFileSearchId;
	// delete assistantCopy.localFunctionInfo;
	// delete assistantCopy.localResponseFormat;
	// delete assistantCopy.localJustSwitchedToJson;

	return assistantCopy;
};
