import type { FC} from "react";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import avatarImg from "../../../../../../shared/assets/img/bot-avatar.png";
import { remark } from "remark";
import html from "remark-html";
import {CopyButton} from "../../../../../../shared/components/Buttons/CopyButton";

interface Props {
  systemMessage: string;
}
export const SystemMessage: FC<Props> = ({ systemMessage }) => {
  const [htmlContent, setHtmlContent] = useState(systemMessage);
  const messageRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const processMarkdown = async () => {
      const processedContent = await remark().use(html).process(systemMessage);

      const newDiv = document.createElement("div");
      newDiv.innerHTML = processedContent.toString();

      setHtmlContent(newDiv.innerHTML);
    };
    processMarkdown();
    scrollToBottom();
  }, [systemMessage]);

  const scrollToBottom = () => {
    iconRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.wrapper}>
      <img
        ref={iconRef}
        src={avatarImg}
        alt="Иконка искр для обозначения системного сообщения"
        className={styles["system-avatar"]}
      />
      <div className={styles["system-message"]}>
        <div
          className={styles["inner-message"]}
          ref={messageRef}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
      <CopyButton
        onClick={() => navigator.clipboard.writeText(systemMessage)}
        position="static"
        className={styles["button"]}
      />
    </div>
  );
};
