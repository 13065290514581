import { Route, Routes, useNavigate } from "react-router-dom";
import { embeddingsPageRoute } from "./embeddings/config";
import { comparisonPageRoute } from "./comparison/config";
import { classificationPageRoute } from "./classification/config";
import { assistantsPageRoute, assistantPageRoute } from "./assistants/config";
import { CircularProgress } from "@mui/material";
import { LoginPage } from "../../pages/LoginPage";
import { APP_MULTI_USER_API } from "../../services/api/constants";
import { getUserInfo } from "../../services/api/methods";
import { useEffect, useState } from "react";
import { recreateAppThreadApi } from "../../services/api/instances/threadApiInstance";
import { recreateAppKeycloakApi } from "../../services/api/instances/keycloakApiInstance";
import { recreateAppModelsApi } from "../../services/api/instances/modelsApiInstance";
import { recreateAppClassificatorToolsApi } from "../../services/api/instances/classificatorApiInstance";
import { recreateAppVectorToolsApi } from "../../services/api/instances/vectorApiInstance";
import { useProfile } from "../../services/profile";
import { databasePageRoute } from "./database/config";
import { chatbotPageRoute } from "./chatbot/config";
import { recreateAppBotApi } from "../../services/api/instances/botApiInstance";
import { multiUserApi } from "../../shared/utils/multiUserFunctions";
import { stylizationPageRoute } from "./stylization/config";
import { Page } from "../../common/Page";
import { navigateToRoute } from "../../shared/utils/navigate-to-route";
import { notFoundPageRoute } from "./not-found/config";

export const routesArray = [
  embeddingsPageRoute,
  comparisonPageRoute,
  classificationPageRoute,
  stylizationPageRoute,
  assistantsPageRoute,
  databasePageRoute,
  chatbotPageRoute,
];

export const AppRouting = () => {
  const { isLoading, setIsLoading, setUserInfo, userInfo } = useProfile();
  const [isApiCreated, setIsApiCreated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isApiCreated) {
      recreateAppThreadApi(setUserInfo, false);
      recreateAppBotApi(setUserInfo, false);
      recreateAppKeycloakApi(setUserInfo);
      recreateAppModelsApi(setUserInfo);
      recreateAppClassificatorToolsApi(setUserInfo);
      recreateAppVectorToolsApi(setUserInfo);
      setIsApiCreated(true);
    }
  }, [isApiCreated]);

  useEffect(() => {
    if (isApiCreated) {
      getUserInfo()
        .then((res) => {
          setIsLoading(false);
          setUserInfo(res);
          if (res.replacement_token) {
            localStorage.setItem(
              APP_MULTI_USER_API,
              multiUserApi.setUserKey(
                multiUserApi.parseToArray(),
                "replacement_token",
                res.replacement_token
              )
            );
          }
          recreateAppThreadApi(
            setUserInfo,
            !!res.replacement_token,
            res.thread_url
          );
          recreateAppModelsApi(setUserInfo, res.thread_model_url);
          recreateAppBotApi(
            setUserInfo,
            !!res.replacement_token,
            res.tg_bot_service_url
          );

          navigateToRoute(res["user-groups"], navigate);
        })
        .catch(() => {
          setIsLoading(false);
          setUserInfo(null);
        });
    }
  }, [isApiCreated]);

  if (isLoading) {
    return <CircularProgress color="success" />;
  }

  if (!userInfo) {
    return <LoginPage />;
  }

  return (
    <Routes>
      <Route element={<Page />}>
        {routesArray.map((route) => {
          if (userInfo["user-groups"].includes(route.path)) {
            if (route.path === assistantsPageRoute.path) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                >
                  <Route
                    path={assistantPageRoute.path}
                    element={assistantPageRoute.element}
                  />
                </Route>
              );
            } else {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            }
          }
        })}
      </Route>
      <Route
        key={notFoundPageRoute.path}
        path={notFoundPageRoute.path}
        element={notFoundPageRoute.element}
      />
    </Routes>
  );
};
