import type { ModalChatState } from "../types";

export function chatReducer(state: ModalChatState, action: any) {
  switch (action.type) {
    case "SET_MODEL_LIST":
      return { ...state, models: action.payload };
    case "SET_SELECTED_MODEL": {
      return {
        ...state,
        selectedModel: action.payload,
      };
    }
    case "SET_MESSAGE": {
      return { ...state, userMessage: action.payload };
    }
    case "SET_MESSAGES": {
      return { ...state, messages: action.payload };
    }
    default:
      return state;
  }
}
