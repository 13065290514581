import axios from "axios";
import { multiUserApi } from "../../../shared/utils/multiUserFunctions";
import { getThreadAccessToken } from "../utils";

let APP_MODELS_API_URL = "/";
if (process.env.REACT_APP_ENV_SOURCE === "dev") {
  APP_MODELS_API_URL = process.env.REACT_APP_THREAD_MODELS || "/";
} else {
  APP_MODELS_API_URL =
    // @ts-ignore
    window.__RUNTIME_CONFIG__?.REACT_APP_THREAD_MODELS;
}

export let appModelsApi = axios.create({
  baseURL: APP_MODELS_API_URL,
});

export const recreateAppModelsApi = (
  setUserInfo: (value: null) => void,
  baseUrl?: string
) => {
  appModelsApi = axios.create({
    baseURL: baseUrl ? baseUrl : APP_MODELS_API_URL,
  });

  appModelsApi.interceptors.request.use((request) => {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${getThreadAccessToken()}`;
      request.headers["X-Keycloak-Token"] =
        multiUserApi.getUserKey("access_token");
    }
    return request;
  });

  appModelsApi.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        if (multiUserApi.parseToArray().length > 1) {
          multiUserApi.removeUser();
          window.location.reload();
        } else {
          multiUserApi.removeUser();
          setUserInfo(null);
        }
      }
      return Promise.reject(error);
    }
  );
};
