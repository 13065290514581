import type { TextGeneration } from "../../../services/api/methodsTypes";
import type { StylistStateType } from "../types";

export const TEXT_GENERATION_POST: TextGeneration = {
  model: "",
  messages: [
    {
      role: "system",
      content: "",
    },
    {
      role: "user",
      content: "",
    },
  ],
  response_format: {
    type: "text",
  },
  temperature: 1,
};

export const INITIAL_STATE: StylistStateType = {
  assistantList: [],
  selectedAssistant: null,
  originalText: "",
  result: "",
  postObject: TEXT_GENERATION_POST,
  dateUpdate: "",
};
